import React, {  useState,useEffect ,useContext} from 'react';
import WhskrPageHeader from "../page-header/WhskrPageHeader";
import "./ReportsPage.scss";
import { Card, DatePicker, Space, Typography, Layout, Select, Button, Row, Tooltip, Col, Tag, Divider, Modal, Popconfirm  } from 'antd';
import AdvancedTable from './../generic-components/advanced-table/AdvancedTable';
import { CommonContext } from '../../context/CommonContext';
import ReportsDrawer from './ReportsDrawer';
import ReportServices from '../../services/ReportServices';
import { momentLocal } from '../util/TimeUtil';
import CommonUtil from '../util/CommonUtil';
import { DownloadIcon, SendtoCloud, TrashIcon } from '../util/SvgUtil';
import {CloudDownloadOutlined,SwapOutlined
} from "@ant-design/icons";
const { Content } = Layout;
const {Text,Link}=Typography;




const ReportsPage = (props) => {
  const [reports, setReports] = useState([]);
  const [openReportsDrawer,setOpenReportsDrawer] = useState(null);
  const commonContext = useContext(CommonContext);

  const getAllReports = () => {
    ReportServices.getAllReports((data)=>{

      let list = [
        // {
        //   "name" : "Dummy Inventory Available",
        //   "type" : "Inventory",
        //   "createdDate":"2022-04-22",
        //  "createdBy": "Carla Polite",
        //  "status": "Completed",
        //  "path": "http://s3......."
        // },
        // {
        //   "name" : "Dummy Inventory Available",
        //   "type" : "Inventory",
        //   "createdDate": "2022-04-22",
        //  "createdBy": "Carla Polite",
        //  "status": "Completed",
        //  "path": ""
        // }
        ] ;
      if(data.length>0){
        setReports(data);
      }else {
        setReports(list);
      }
    
    });

  }
  

  useEffect(()=>{
    
    getAllReports();
    const timer = setInterval(()=>getAllReports(),30000)


    return () => clearInterval(timer);
},[]);
 

const columns = () => [
  {
    title: 'Name',
    dataIndex: 'name'
    
},
  {
      title: 'Type',
      dataIndex: 'type',
      filters: [
        {
          text: 'Accounts Receivable',
          value: 'Accounts Receivable',
        },
        {
          text: 'Billable Items',
          value: 'Billable Items',
        },
        {
            text: 'End Of Shift',
            value: 'End Of Shift',
          },
          {
            text: 'Inventory',
            value: 'Inventory',
          },
          {
            text: 'Sales & Sales Tax',
            value: 'Sales & Sales Tax',
          },
          {
              text: 'Usage',
              value: 'Usage',
            },
            {
              text: 'Vaccine',
              value: 'Vaccine',
            },
        
      ],
      onFilter: (value, record) => record.type === value,
      
  },
  {
    title: 'Date Created',
    dataIndex: 'createdDate',
    render:(text)=> text? momentLocal(text,"YYYY-MM-DD").format("MM/DD/YYYY"):"-",
    sorter: (a, b) =>{return  momentLocal(a.createdDate,"YYYY-MM-DD").diff(momentLocal(b.createdDate,"YYYY-MM-DD"))},
    
},{
    title: 'Created By',
    dataIndex: 'createdBy'
},
{
  title: 'Status',
  dataIndex: 'status',
  render:(text)=><Tag style={{borderRadius:"4px", width:"8em",textAlign:"center", backgroundColor:CommonUtil.STATUS_OBJECT_BG_COLOR[text?.toUpperCase()] , border:" 1px solid "+CommonUtil.STATUS_OBJECT_COLOR[text?.toUpperCase()]}}  color={CommonUtil.STATUS_OBJECT_COLOR[text?.toUpperCase()]}>
      <Text style={{color:CommonUtil.STATUS_OBJECT_COLOR[text?.toUpperCase()]}}>{text}</Text></Tag>
},
{
  title: 'Action',
  dataIndex: 'path',
  render:(text,record)=>  
  <Row gutter={[24,0]}>
  {/* <Col><Tooltip title="Rerun Report"><Link ><SwapOutlined style={{ color:"black",fontSize: '1.5em' }} /></Link></Tooltip></Col> */}
  <Col><Tooltip title="Download Report"><Link disabled={!text || record.status!=='Completed'} onClick={()=>window.open(text,"_blank","noreferrer")} ><DownloadIcon style={{ fontSize: '1.5em' }} /></Link></Tooltip></Col>
  <Col>
  <Popconfirm
                title="Are you sure you want to delete this report?"
                okText="Yes"
                cancelText="No"
                onConfirm={
                  ()=>{ReportServices.deleteReportById(record.id,()=>getAllReports())}
                }><Tooltip title="Delete Report"><Link><TrashIcon style={{ fontSize: '1.5em' }} /></Link></Tooltip></Popconfirm></Col>

  </Row>
}
];

  return (
    <Layout id="reports-page">
            <WhskrPageHeader title="Reports"
             extra={[<Button className="createNewBTN" type="primary" size="large" onClick={()=>setOpenReportsDrawer({})} shape="round">Create a Report</Button>]}  />
            <Content className="masterContentPadding">
                <Card className="lab-list-card pageCardContainer" 
                title = "Report History"
                bordered = {false}
                >
                    <AdvancedTable
                        dataSource={reports}
                        rowKey="id"
                        columns={columns()}
                        scroll={{ x: 1500, y: "calc(100vh - 370px)" }}
                    />
                    </Card>
            </Content>
           
           {openReportsDrawer && 
           <ReportsDrawer 
           onSuccess = {()=>{setOpenReportsDrawer(null);getAllReports();}}
           onClose = {()=>setOpenReportsDrawer(null)}/>}
        </Layout>
  );
};

export default ReportsPage;

