import { message, Typography } from 'antd';
import axios from "axios";
import { trackPromise } from 'react-promise-tracker';
import CommonUtil from "../components/util/CommonUtil";

const { Text } = Typography;
export default class InventoryServices {
    

    static createInventory = (inputData , dataUpdaterFunc=()=>{}) => {

      



        let resultStatus = true;
        trackPromise(
         axios.post("/api-route/api/v1/service/inventory",inputData)
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            if(resultStatus){
                message.success("Inventory "+CommonUtil.status_messages.create_success)
            } else {
                message.error(CommonUtil.status_messages.error)
            }
         
            dataUpdaterFunc(resultStatus)
        }
    
        )
        );

    }

    static updateInventory = (inputData , dataUpdaterFunc=()=>{}) => {


       
        let resultStatus = true;
        trackPromise(
         axios.put("/api-route/api/v1/service/inventory",inputData)
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            if(resultStatus){
                message.success("Inventory "+CommonUtil.status_messages.update_success)
            } else {
                message.error(CommonUtil.status_messages.error)
            }
         
            dataUpdaterFunc(resultStatus)
        }
    
        )
        );

    }

    static getAllInventory = (dataUpdaterFunc=()=>{}) => {
        let resultantData = [];
        trackPromise(
         axios.get("/api-route/api/v1/service/inventory")
        .then(response => resultantData=response.data)
        .catch(err => console.log("Error occurred at getAllInventory API: " + err))
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }

    static getInventoryById = (id,dataUpdaterFunc=()=>{}) => {


        

        let resultantData = [];
        trackPromise(
         axios.get("/api-route/api/v1/service/inventory/"+id)
        .then(response => resultantData=response.data)
        .catch(err => console.log("Error occurred at getInventoryById API: " + err))
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }

    static searchAllItems = (dataUpdaterFunc=()=>{}) => {
      
        let resultantData = [];
        trackPromise(
         axios.get("/api-route/api/v1/service/inventory-basic")
        .then(response => resultantData=response.data)
        .catch(err => console.log("Error occurred at searchAllItems API: " + err))
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }

    static searchItemByType = (type,dataUpdaterFunc=()=>{}) => {
        
        let resultantData = [];
        trackPromise(
         axios.get("/api-route/api/v1/service/inventory-basic?inventory-type="+type)
        .then(response => resultantData=response.data)
        .catch(err => console.log("Error occurred at searchItemByType API: " + err))
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );



    }

    static getLowInventoryData = (dataUpdaterFunc=()=>{}) => {
        let resultantData =[
            {
                "id": 1319,
                "product": "Rimadyl 50mg/ml (Carprofen) 20ml",
                "onHand": 6.39
            },
            {
                "id": 1320,
                "product": "Midazolam HCl 10ml",
                "onHand": 86.97
            }
        ]
        trackPromise(
         axios.get("/api-route/api/v1/service/inventory-low")
        .then(response => resultantData = response.data)
        .catch(err => console.log("Error occurred at getLowInventoryData API: " + err))
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }

    static getExpiredLots = (dataUpdaterFunc=()=>{}) => {
        let resultantData =[
            {
                "lotId": 1,
                "inventoryId": 532,
                "qty": 20.0,
                "lotNumber": "12345",
                "manufacturer": "Test",
                "expiryDate": "2021-12-31",
                "productName": "Midazolam HCl 10ml"
            }
        ]
        trackPromise(
         axios.get("/api-route/api/v1/service/inventory-lot")
        .then(response => resultantData = response.data)
        .catch(err => console.log("Error occurred at getExpiredLots API: " + err))
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }


    static deleteInventoryById = (id,dataUpdaterFunc=()=>{}) => {
        let resultStatus = true;
        trackPromise(
         axios.delete("/api-route/api/v1/service/inventory/"+id)
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            if(resultStatus){
                message.success("Inventory "+CommonUtil.status_messages.delete_success)
            } else {
                message.error(CommonUtil.status_messages.error)
            }
         
            dataUpdaterFunc(resultStatus)
        }
    
        )
        );

      
    
    }


    static getInventoryCategories = (dataUpdaterFunc=()=>{}) => {
        let resultantData =[
           
        ]
        trackPromise(
         axios.get("/api-route/api/v1/service/inventory-category")
        .then(response => resultantData = response.data)
        .catch(err => console.log("Error occurred at getInventoryCategories API: " + err))
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }

    static saveInventoryCategories = (name,dataUpdaterFunc=()=>{}) => {
        // {
        //     "name": "Test first Category"
        // }
        let resultStatus = 0;
        trackPromise(
         axios.post("/api-route/api/v1/service/inventory-category",{name})
         .then(response =>resultStatus= response.data)
        .catch(err => console.log("Error occurred at saveInventoryCategories API: " + err))
        .finally( () => {
            dataUpdaterFunc(resultStatus)
        }

        )
        );

    }


}