import React , {useState,useContext,useEffect} from 'react';
import './App.scss';
import RegistrationForm from "./components/registration/RegistrationForm";
import LoginForm from "./components/login/LoginForm";
import Landing from "./components/landing/Landing";
import { BrowserRouter as Router, Redirect, Route, useLocation } from "react-router-dom";
//import { CustomSpinSvg } from './components/util/SvgUtil';
import CustomSpinner from './components/generic-components/customSpinner/CustomSpinner';
import { CommonContext } from './context/CommonContext';
import CommonServices from './services/CommonServices';
import PrintLabelPreview from './components/labels/PrintLabelPreview';
import EstimatePreview from './components/estimate/preview/EstimatePreview';
import RabiesCertificatePreview from './components/labels/RabiesCertificatePreview';
import axios from 'axios';
import LoginServices from './services/LoginServices';
import IntermediatePage from './components/landing/IntermediatePage';
import _debounce from 'lodash.debounce';
import ApproveEstimateHtml from './components/static-files/ApproveEstimateHtml';
import AppointmentConfirmationHtml from './components/static-files/AppointmentConfirmationHtml';
import OnboardPage from './components/onboard-welcome-page/OnboardPage';


//const CustomSpinIcon = props => <Icon component={CustomSpinSvg} {...props} />;
const CommonCalls = (props) => {
  let loginState = props.pageLoadData.loginState;
  const setLoginState = (data) => {props.setPageLoadData(k=>({...k,loginState:data}))}
  let context = useContext(CommonContext);
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = _debounce(() => setWidth(window.innerWidth), 100)

    window.addEventListener('resize', handleResize);

   

    // console.log("width size =sda==>",width);
     let isTablet = ( width <= 1370) ? true : false ;
    //  console.log("width size ===>",width , isTablet);
     context.updateStateFields({isTabletMode: isTablet })
   
    
  }, [width]);
  

  useEffect(() => {
    axios.defaults.headers.common['tz'] = Intl.DateTimeFormat().resolvedOptions().timeZone;

    axios.interceptors.response.use(response => {
      return response;
    }, error => {
     if (error.response.status === 401) {
      localStorage.removeItem ('user-token');
      delete axios.defaults.headers.common["Authorization"];
      //setRedirectUrl("/login");
      
      if(window.location.pathname !== '/login')
          window.location = "/login";
      else if (error.response.headers.location === '/')
        //window.location = '/main' ;
        setLoginState("login-success");
        else
        setLoginState("not-login");

      

    }
     return Promise.reject(error.response);
     ;
    });

    if(!["/reset-password","/register","/estimate-approve","/appt-confirm","/onboard","/dummy"].some(k=>window.location.pathname.includes(k))){  
       //if(window.location.pathname !== '/login'){
      LoginServices.getLoggedInUserDetails((data)=>{if(data){ setLoginState("login-success")}else { 
                if(window.location.pathname !== '/login') {
                  window.location = "/login";
                }
    }});
    }


   
    
  }, []);

  useEffect(()=>{
    if(loginState === "login-success" ){

      if(["/label/preview","/estimate/preview","/rabies/preview"].some(k=>window.location.pathname.includes(k))){
        props.setPageLoadData(k=>({...k,loginState:"authenticated",redirectUrl:window.location.pathname}));

      }else {
      LoginServices.getLoggedInUserDetails((userProfile)=>{
        if(userProfile){
          context.updateStateFields ( {userProfile});
          LoginServices.getLoggedInUserBranchDetails((branchDetails)=>{
            context.updateStateFields ( {branchDetails});
            if(branchDetails.length === 1){
               LoginServices.updateDefaultBranch(branchDetails[0].branchId,(data)=>{
                context.updateStateFields ({defaultBranch:branchDetails[0]});
                props.setPageLoadData(k=>({...k,loginState:"authenticated",redirectUrl:"/main"}));

               });
            }else {
              props.setPageLoadData(k=>({...k,loginState:"authenticated",redirectUrl:branchDetails.length>1?"/branch":"/main"}));

            }
            CommonServices.getAllStatesInUS((states)=> {
              context.updateStateFields ( {allStates:(states.reduce((total,current)=> {total[current.stateId]=current;return total} ,{}))})
             } );
          });

        }else {
          window.location = "/login";
        }

      });
    }

    
    }

  },[props.pageLoadData.loginState])

  return null;
  
}
const App = () => {
  let searchParams = new URLSearchParams(window.location.search)
  let code = /code=(.*)/.exec(window.location.href);
  if(searchParams.has('code') ){
    code = searchParams.get('code')
    
  }

  const [vetcoveCode , setVetcoveCode] = useState(code && code.length > 1 ? code : null);

  // const [userDetails,setUserDetails] = useState({isAuthenticated:false});

  // const [redirectUrl,setRedirectUrl] = useState();

  // useEffect(()=>{
  //   axios.interceptors.response.use(response => {
  //     return response;
  //   }, error => {
  //    if (error.response.status === 401) {
  //     localStorage.removeItem ('user-token');
  //     delete axios.defaults.headers.common["Authorization"];
  //     //setRedirectUrl("/login");
  //     console.log("..==>",error);
  //     window.location = "/login";
      

  //   }
  //    return Promise.reject(error.response);
  //    ;
  //   });

  // },[]);

  // useEffect(()=>{
  //   const script = document.createElement("script");
  
  //   script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyDbjIB9MbaQ8qMnGbs1vczLFnmuqCWR604&libraries=places";
  //   script.async = true;
  
  //   document.body.appendChild(script);
  // },[]);
  const [pageLoadData,setPageLoadData] = useState({loginState:"in-progress",redirectUrl:"/main"});

  return (

          <CustomSpinner isSpinning={pageLoadData.loginState === "login-success"?true:null} component = {
            <div className="App">
          <CommonCalls pageLoadData={pageLoadData} setPageLoadData={setPageLoadData}/>
      <Router>
        
        <Route exact path="/login">
        {pageLoadData.loginState === "not-login"?<LoginForm type="login" />:<IntermediatePage/>}
        </Route>
        <Route exact path="/register">
          <RegistrationForm />
        </Route>
        <Route exact path="/onboard">
        <OnboardPage type="onboard" />
        </Route>
        <Route exact path="/dummy">
        <LoginForm type="login" />
        </Route>
        <Route exact path="/estimate-approve" component={({match,location})=>{
          let search = new URLSearchParams(location.search);
          return <ApproveEstimateHtml   extra={{token:search.get("token")}}/>;}} />   
        <Route exact path="/appt-confirm" component={({match,location})=>{
          let search = new URLSearchParams(location.search);
          return <AppointmentConfirmationHtml   extra={{token:search.get("token")}}/>;}} /> 
        <Route exact path="/reset-password/:uuid" component={({match,location})=>{
          let search = new URLSearchParams(location.search);
          return <LoginForm type="reset-pwd" subType="reset-pwd" extra={{token:search.get("token"),uuid:match.params.uuid,createpass:search.get("new")}}/>;}} />    
     
        {pageLoadData.loginState === "authenticated" && <>
       
        <Route path="/main">
          <Landing />
        </Route>
        <Route exact path="/">
          <Redirect to="/main" />
        </Route>
        <Route exact path="/branch">
          <LoginForm type="welcome" />
        </Route>
        <Route exact path="/label/preview">
          <PrintLabelPreview/></Route>
        <Route exact path="/estimate/preview/:isEstimate" render={(props) => (
            <EstimatePreview isEstimate={props.match.params.isEstimate === 'true'}/>
        )} />
           
        <Route exact path="/rabies/preview">
          <RabiesCertificatePreview/></Route>
          <Redirect to={pageLoadData.redirectUrl} />
          </>}
      </Router>


    </div>
          } />

  );
};

export default App;
