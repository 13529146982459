import React, { useContext, useEffect, useState } from 'react';
import { Button, Drawer, Typography, Row, Col, Modal, Select, Divider } from 'antd';
import CustomImage from '../generic-components/custom-image/CustomImage';


import PatientServices from '../../services/PatientServices';
import { CommonContext } from '../../context/CommonContext';
import BillingsTab from '../estimate/BillingsTab';

const { Text } = Typography;
const { Option } = Select;

const SelectPatientModal = (tempProps) => {
    const [patientData, setPatientData] = useState({ id: null });


    return (<Modal onCancel={tempProps.onClose} footer={null} visible={true} title="Choose Patient">
        <Row style={{ marginBottom: 24 }} ><Col><Text>Choose the patient that you'd like to create an invoice for</Text></Col></Row>
        <Row style={{ marginBottom: 8 }}><Col span={24}>
            <Select
                showSearch={true}
                value={patientData.id}
                size="large"

                filterOption={(input, option) =>
                    option.extra.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(value, option) => {
                    setPatientData(option.data);
                }}
                placeholder={<Text>Select a Patient</Text>}
                style={{ width: "100%", marginBottom: '24px' }}>
                {tempProps.patientDetails.map(k => <Option key={k.id} value={k.id} data={k} extra={k.name}>
                    <Row><Col> <Text style={{ fontWeight: 500, lineHeight: "14px" }}> {k.name} <br /></Text>
                        <Text className="font-size-12" style={{ fontWeight: 400, lineHeight: "12px" }} type="secondary">{k.breed}</Text></Col></Row>

                </Option>)}


            </Select></Col></Row>
        <Divider style={{ marginTop: '60px' }} />
        <Row justify="space-between" gutter={[16, 0]}><Col span={12}> <Button onClick={tempProps.onClose}
            shape="round"
            size="large"
            block>
            Cancel
        </Button></Col><Col span={12}><Button
            type="primary"
            size="large"
            shape="round"
            disabled={!patientData.id}
            block
            onClick={() => {
                PatientServices.fetchPatientById(patientData.id, (data) => {
                    let client = { id: data.clientId, lastName: data.clientLastName };
                    // tempProps.setInvoiceAction({type:"invoice", requiredData:{billingsData:{},record:{patientId:patientData.id,patient:{...patientData,image:data.image},client},isEdit:false}})
                    tempProps.setExtraData(k => ({ ...k, record: { patientId: patientData.id, patient: { ...patientData, image: data.image }, client } }))

                })
                //onCreateOrEditInvoice(patientData.id,tempProps.setInvoiceAction,false,{});
            }

            }
        >Create Invoice
        </Button></Col></Row>
    </Modal>);
}

const NewInvoiceDrawer = (props) => {

    const commonContext = useContext(CommonContext);
    const [extraData, setExtraData] = useState({ record: props.record ?? {}, patientDetails: [] });

    useEffect(() => {
        if (!props.isEdit && !props.record?.patientId) {
            PatientServices.fetchAllPatients((data) => setExtraData(k => ({ ...k, patientDetails: data })));

        }

    }, [props.record, props.isEdit])
    const onClose = () => props.onClose();
    return (<>
        {!props.isEdit && !extraData.record?.patientId ? <SelectPatientModal
            setExtraData={setExtraData}
            patientDetails={extraData.patientDetails}
            onClose={onClose} /> :
            <Drawer className='estimate-drawer'
                title={props.isEdit ? ("Invoice " + props.inputData.id) : "Create Invoice"}
                width={800}
                onClose={onClose}
                visible={true}
                bodyStyle={{ paddingBottom: 50 }}
                footer={null}>

                <Row justify="start" align="middle" gutter={[16, 0]}>
                    <Col >
                        <CustomImage styling={{
                            width: '65px',
                            height: '65px',
                            showOuterBorder: true,
                            url: `url(` + extraData.record.patient?.image + `)`,
                            fullName: '' // pass dynamic full name
                        }} /></Col>
                    <Col>

                        <Row><Col><Text style={{ fontSize: "17px", fontWeight: 500 }} >{extraData.record.patient?.name}</Text></Col></Row>
                        <Row><Col><Text type="secondary" >{extraData.record.patient?.breed}</Text></Col></Row>
                    </Col></Row>
                <BillingsTab
                    refreshData={(invoiceId) => props.onRefresh(invoiceId)}
                    onSuccessReturn={props.onSuccessReturn}
                    billingsData={props.inputData ?? { items: [] }}
                    patientId={extraData.record.patient?.id}
                    apptId={null}
                    clientId={props.isEdit ? props.record.client.id : extraData.record.client.id}
                    showBadge={(data) => { }}
                    patientName={extraData.record.patient?.name}/>
               
            </Drawer>
        }</>
    );

}
export default NewInvoiceDrawer;