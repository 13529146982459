import React from "react";
import { WhskrPetEmpty } from "./components/util/EmptySvgUtil";
import { Col, Row, Typography } from "antd";
const { Text, Title } = Typography;
export default class ErrorBoundary extends React.Component {
  
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
   
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      // logErrorToMyService(error, errorInfo);
      console.error("Some error occured in runtime")
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return <>  <Row justify="center" align="middle" style={{marginTop:'12em'}}><Col><WhskrPetEmpty /></Col></Row>
        <Row justify="center" align="middle"><Col><Text className="empty-text" >Whoofs! There was an issue</Text></Col></Row>
        <Row justify="center" align="middle"><Col><Text className="empty-text" >Please contact support@whskr.com</Text></Col></Row> </>;
      }else {
        return this.props.children; 
      }
  
    }
  }