import axios from "axios";
import { trackPromise } from 'react-promise-tracker';

export default class CommonServices {

    static getAllStatesInUS = (dataUpdaterFunc = () => { }) => {
        //dataUpdaterFunc([]);

        let resultantData = [];
        trackPromise(
            axios.get("/api-route/api/v1/service/state/us/")
                .then(response => resultantData = response.data)
                .catch(err => {})
                .finally(() => {

                    dataUpdaterFunc(resultantData);

                })

        );


    }

    static getAllClientsAndPatients = (updateContextFields) => {
        //dataUpdaterFunc([]);

        let resultantData =[];
        // let resultantData =
        //     [
        //         {
        //             "id": 11588,
        //             "name": "Red Bandanna Red Bandanna",
        //             "type": "C"
        //         },
        //         {
        //             "id": 11589,
        //             "name": "Reann Ring",
        //             "type": "C"
        //         },
        //         {
        //             "id": 11590,
        //             "name": "Annalise Kaylor",
        //             "type": "C"
        //         },
        //         {
        //             "id": 11243,
        //             "name": "Pippin Paterson",
        //             "type": "P",
        //             "breed": "Labrador Retriever Mix"
        //         },
        //         {
        //             "id": 11244,
        //             "name": "Astro Panzer",
        //             "type": "P",
        //             "breed": "Golden Retriever"
        //         },
        //         {
        //             "id": 11245,
        //             "name": "Stella Campbell",
        //             "type": "P",
        //             "breed": "German Shorthaired Pointer"
        //         }];
        trackPromise(
            axios.get("/api-route/api/v1/service/global/search")
                .then(response => resultantData = response.data)
                .catch(err => {})
                .finally(() => { 
                    updateContextFields({clientPatientDetails:resultantData})
                    //dataUpdaterFunc(resultantData); 
                })

        );


    }


}