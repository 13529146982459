import axios from "axios";
import React  from "react";
import { message, Typography} from 'antd';
import { trackPromise} from 'react-promise-tracker';
import moment from "moment";
import CommonUtil from "../components/util/CommonUtil";


const { Text } = Typography;
export default class VitalServices {


    static createVitals = (inputData , dataUpdaterFunc=()=>{}) => {
        let resultStatus = true;
        trackPromise(
         axios.post("/api-route/api/v1/service/exam/anes/vital",inputData)
        .then(response => response.data)
        .catch(err => {resultStatus=false;console.log("Error occurred at createVitals API: " + err);})
        .finally( () => {
            if(resultStatus){
                message.success("Vitals "+CommonUtil.status_messages.create_success)
            } else {
                message.error(CommonUtil.status_messages.error)
            }
            dataUpdaterFunc(resultStatus)
        }

        )
        );

    }

    static updateVitals = (inputData , dataUpdaterFunc=()=>{}) => {
        let resultStatus = true;
        trackPromise(
         axios.put("/api-route/api/v1/service/exam/anes/vital",inputData)
        .then(response => response.data)
        .catch(err => {resultStatus=false;console.log("Error occurred at updateVitals API: " + err);})
        .finally( () => {
            if(resultStatus){
                message.success("Vitals "+CommonUtil.status_messages.update_success)
            } else {
                message.error(CommonUtil.status_messages.error)
            }
            dataUpdaterFunc(resultStatus)
        }

        )
        );

    }

    static getVitalsById = (id,dataUpdaterFunc=()=>{}) => {
        let resultantData = [];
        trackPromise(
         axios.get("/api-route/api/v1/service/exam/anes/vital/"+id)
        .then(response => resultantData=response.data)
        .catch(err => console.log("Error occurred at getVitalsById API: " + err))
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }

    static getVitalsByApptId = (id,dataUpdaterFunc=()=>{}) => {
        let resultantData = [];
        trackPromise(
         axios.get("/api-route/api/v1/service/exam/anes/vital/appt/"+id)
        .then(response => resultantData=response.data)
        .catch(err => console.log("Error occurred at getVitalsByApptId API: " + err))
        .finally( () => {
            if(resultantData.length>0){
                resultantData.forEach(k => {
                    k.attr = k.attr.reduce((total,current) => {total[current.id]=current.value;return total;},{});
                })
            }
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }





}