import axios from "axios";
import React  from "react";
import { message, Typography} from 'antd';
import { trackPromise} from 'react-promise-tracker';
import moment from "moment";
import CommonUtil from "../components/util/CommonUtil";

const { Text } = Typography;
export default class MedicationServices {


    static createMedication = (inputData , dataUpdaterFunc=()=>{}) => {
        let resultStatus = true;
        trackPromise(
         axios.post("/api-route/api/v1/service/exam/anes/med",inputData)
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            if(resultStatus){
                message.success("Medication "+CommonUtil.status_messages.create_success)
            } else {
                message.error(CommonUtil.status_messages.error)
            }
         
            dataUpdaterFunc(resultStatus)
        }
    
        )
        );

    }

    static updateMedication = (inputData , dataUpdaterFunc=()=>{}) => {
        let resultStatus = true;
        trackPromise(
         axios.put("/api-route/api/v1/service/exam/anes/med",inputData)
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            if(resultStatus){
                message.success("Medication "+CommonUtil.status_messages.update_success)
            } else {
                message.error(CommonUtil.status_messages.error)
            }
         
            dataUpdaterFunc(resultStatus)
        }
    
        )
        );

    }

    static deleteMedication = (id , dataUpdaterFunc=()=>{}) => {
        let resultStatus = true;
        trackPromise(
         axios.delete("/api-route/api/v1/service/exam/anes/med/"+id)
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            if(resultStatus){
                message.success("Medication "+CommonUtil.status_messages.delete_success)
            } else {
                message.error(CommonUtil.status_messages.error)
            }
         
            dataUpdaterFunc(resultStatus)
        }
    
        )
        );

    }

    static getMedicationById = (id,dataUpdaterFunc=()=>{}) => {
        let resultantData = [];
        trackPromise(
         axios.get("/api-route/api/v1/service/exam/anes/med/"+id)
        .then(response => resultantData=response.data)
        .catch(err => console.log("Error occurred at getMedicationById API: " + err))
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }

    static getMedicationByApptId = (id,dataUpdaterFunc=()=>{}) => {
        let resultantData = [];
        trackPromise(
         axios.get("/api-route/api/v1/service/exam/anes/med/appt/"+id)
        .then(response => resultantData=response.data)
        .catch(err => console.log("Error occurred at getMedicationByApptId API: " + err))
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }


    static getMedicationByApptIdandTypeId = (apptid,typeid,dataUpdaterFunc=()=>{}) => {
        let resultantData = [];
        trackPromise(
         axios.get("/api-route/api/v1/service/exam/anes/med/appt/"+apptid+"/type/"+typeid)
        .then(response => resultantData=response.data)
        .catch(err => console.log("Error occurred at getMedicationByApptIdandTypeId API: " + err))
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }

    static getMedicationLabelDetails = (labelid,dataUpdaterFunc=()=>{}) => {
        // {
        //     "strength": null,
        //     "rxInstructions": null,
        //     "lotNumber": "123",
        //     "rxNumber": "1001",
        //     "expirationId": null,
        //     "expirationDate": null
        // }
        
        let resultantData = {};
        trackPromise(
         axios.get("/api-route/api/v1/service/patient/treat/med/"+labelid)
        .then(response => resultantData=response.data)
        .catch(err => console.log("Error occurred at getMedicationLabelDetails API: " + err))
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }

    static getMedicationLabelDetailsForPrint = (medId,id,patientId,isAppt,dataUpdaterFunc=()=>{}) => {
        // {
        //     "strength": null,
        //     "rxInstructions": null,
        //     "lotNumber": "123",
        //     "rxNumber": "1001",
        //     "expirationId": null,
        //     "expirationDate": null
        // }

        let type = isAppt?"appt":"billing";
        let resultantData = {};
        trackPromise(
         axios.get(`/api-route/api/v1/service/patient/treat/med-label/${medId}/${type}/${id}/patient/${patientId}`)
        .then(response => resultantData=response.data)
        .catch(err => console.log("Error occurred at getMedicationLabelDetails API: " + err))
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );


    }

    static saveMedicationLabel = (inputData , dataUpdaterFunc=()=>{}) => {

        // {
        //     "clientId": 5,
        //     "patientId": 5,
        //     "apptId": 5,
        //     "inventoryId": 5,
        //     "name": "Test",
        //     "stDate": "2021-05-05",
        //     "strength": "20 mg",
        //     "refills": 4,
        //     "refillExpiration": 2,
        //     "rxInstructions": "test instructon",
        //     "providerId": 15,
        //     "lotNumber": "3141234",
        //     "rxNumber": "1001"
        // }
        let resultStatus = true;
        let medId = null;
       
        trackPromise(
            axios({
                method:inputData?.patientMedId ? 'put' : 'post',
                url: "/api-route/api/v1/service/patient/treat/med-label",
                data: inputData
            })
        .then(response => medId= response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            if(resultStatus){
                message.success("Label "+CommonUtil.status_messages.create_success)
            } else {
                message.error(CommonUtil.status_messages.error)
            }
         
            dataUpdaterFunc(medId)
        }
    
        )
        );

    }





}