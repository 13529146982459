import axios from "axios";
import React  from "react";
import { message, Typography} from 'antd';
import { trackPromise} from 'react-promise-tracker';
import moment from "moment";
import CommonUtil from "../components/util/CommonUtil";

const { Text } = Typography;
export default class ReminderServices {
    

    static createReminder = (inputData , dataUpdaterFunc=()=>{}) => {
        let resultStatus = true;
        trackPromise(
         axios.post("/api-route/api/v1/service/reminder",inputData)
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            if(resultStatus){
                message.success("Reminder "+CommonUtil.status_messages.create_success)
            } else {
                message.error(CommonUtil.status_messages.error)
            }
         
            dataUpdaterFunc(resultStatus)
        }
    
        )
        );

    }

    static updateReminder = (inputData , dataUpdaterFunc=()=>{}) => {
        let resultStatus = true;
        trackPromise(
         axios.put("/api-route/api/v1/service/reminder",inputData)
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            if(resultStatus){
                message.success("Reminder "+CommonUtil.status_messages.update_success)
            } else {
                message.error(CommonUtil.status_messages.error)
            }
         
            dataUpdaterFunc(resultStatus)
        }
    
        )
        );

    }

    static completeReminder = (id ,source , dataUpdaterFunc=()=>{}) => {
        let resultStatus = true;
        trackPromise(
         axios.put("/api-route/api/v1/service/reminder/"+id+"?source="+source)
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            if(resultStatus){
                message.success("Reminder "+CommonUtil.status_messages.complete_success)
            } else {
                message.error(CommonUtil.status_messages.error)
            }
         
            dataUpdaterFunc(resultStatus)
        }
    
        )
        );

    }

    static deleteReminder = (id,source , dataUpdaterFunc=()=>{}) => {
        let resultStatus = true;
        trackPromise(
         axios.delete("/api-route/api/v1/service/reminder/"+id+"?source="+source)
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            if(resultStatus){
                message.success("Reminder "+CommonUtil.status_messages.delete_success)
            } else {
                message.error(CommonUtil.status_messages.error)
            }
         
            dataUpdaterFunc(resultStatus)
        }
    
        )
        );

    }

    static getAllReminderForHome = (dataUpdaterFunc=()=>{}) => {
        let resultantData = [];
        trackPromise(
         axios.get("/api-route/api/v1/service/reminder")
        .then(response => resultantData=response.data)
        .catch(err => console.log("Error occurred at getAllReminderForHome API: " + err))
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }

    static getRemindersForStaff = (staffId,dataUpdaterFunc=()=>{}) => {
        let resultantData = [];
        trackPromise(
         axios.get("/api-route/api/v1/service/reminder/user/"+staffId)
        .then(response => resultantData=response.data)
        .catch(err => console.log("Error occurred at getRemindersForStaff API: " + err))
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }

    static getReminderById = (id,source,dataUpdaterFunc=()=>{}) => {
        let resultantData = [];
        trackPromise(
         axios.get("/api-route/api/v1/service/reminder/"+id+"?source="+source)
        .then(response => resultantData=response.data)
        .catch(err => console.log("Error occurred at getReminderById API: " + err))
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }

    static getReminderViewById = (id,source,dataUpdaterFunc=()=>{}) => {
        //let resultantData = [{"id":660,"type":"L",code:"QWE1","category":"Specialty Laboratory","pName":"Parvo and Distemper titer (Cornell)","price":95.0},{"id":661,code:"RTY2","type":"L","category":"Specialty Laboratory","pName":"Panleukopenia titer (Cornell)","price":95.0},{"id":662,code:"OIU6","type":"L","category":"Specialty Laboratory","pName":"Thyroid MSU-TT3, TT4, FT4, FT3, FT4ED, TSH, T4AA, T3AA, TgAA","price":238.0},{"id":663,code:"LKJ7","type":"L","category":"Professional Services","pName":"Glucose - Glucometer","price":28.0}];
        let resultantData = [];
        trackPromise(
         axios.get("/api-route/api/v1/service/reminder/view/"+id+"?source="+source)
        .then(response => resultantData=response.data)
        .catch(err => console.log("Error occurred at getReminderViewById API: " + err))
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }

    static getCompletedReminderViewById = (id,source,dataUpdaterFunc=()=>{}) => {
        //let resultantData = [{"id":660,"type":"L",code:"QWE1","category":"Specialty Laboratory","pName":"Parvo and Distemper titer (Cornell)","price":95.0},{"id":661,code:"RTY2","type":"L","category":"Specialty Laboratory","pName":"Panleukopenia titer (Cornell)","price":95.0},{"id":662,code:"OIU6","type":"L","category":"Specialty Laboratory","pName":"Thyroid MSU-TT3, TT4, FT4, FT3, FT4ED, TSH, T4AA, T3AA, TgAA","price":238.0},{"id":663,code:"LKJ7","type":"L","category":"Professional Services","pName":"Glucose - Glucometer","price":28.0}];
        /*
            {
    "id": 7,
    "reminder": "Phone Call",
    "due": "2021-09-30",
    "completedTime": "2021-10-06T21:14:42",
    "patient": {
        "id": 6,
        "name": "Lula Mae Ortiz",
        "breed": "Shih Tzu"
    },
    "client": {
        "name": "Laura Ortiz",
        "email": "laura.ortiz89@gmail.com",
        "phone": "+1 (706) 248-7194"
    }
}

        */
        let resultantData = [];
        trackPromise(
         axios.get("/api-route/api/v1/service/reminder-history/view/"+id+"?source="+source)
        .then(response => resultantData=response.data)
        .catch(err => console.log("Error occurred at getCompletedReminderViewById API: " + err))
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }


   


}