import axios from "axios";
import React  from "react";
import { message, Typography} from 'antd';
import { trackPromise} from 'react-promise-tracker';
import moment from "moment";
import CommonUtil from "../components/util/CommonUtil";

const { Text } = Typography;
export default class PackageServices {


    static createPackage = (inputData , dataUpdaterFunc=()=>{}) => {

        /*


        {
    "name" : "Test Inventory Package1",
    "code" : "P0002",
    "total" : 645.00,
    "adjustedPrice" : 620.00,
    "discount" : 6.5,
    "items" : [
        {
            "id": 1,
            "qty" : 2
        },
        {
             "id": 3,
            "qty" : 1
        }
    ]
} 


        */


        let resultStatus = true;
        trackPromise(
         axios.post("/api-route/api/v1/service/inventory-package",inputData)
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            if(resultStatus){
                message.success("Package "+CommonUtil.status_messages.create_success)
            } else {
                message.error(CommonUtil.status_messages.error)
            }
         
            dataUpdaterFunc(resultStatus)
        }
    
        )
        );

    }

    static updatePackage = (inputData , dataUpdaterFunc=()=>{}) => {
        /*

            {
    "id" : 1,
    "name" : "Test Inventory Package1",
    "code" : "P0002",
    "total" : 645.00,
    "adjustedPrice" : 620.00,
    "discount" : 6.5,
    "items" : [
        {
            "id": 1,
            "qty" : 2
        },
        {
             "id": 3,
            "qty" : 1
        }
    ]
}
        */
        let resultStatus = true;
        trackPromise(
         axios.put("/api-route/api/v1/service/inventory-package",inputData)
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            if(resultStatus){
                message.success("Package "+CommonUtil.status_messages.update_success)
            } else {
                message.error(CommonUtil.status_messages.error)
            }
         
            dataUpdaterFunc(resultStatus)
        }
    
        )
        );

    }


    static deletePackage = (id , dataUpdaterFunc=()=>{}) => {
        /*

            {
    "id" : 1,
    "name" : "Test Inventory Package1",
    "code" : "P0002",
    "total" : 645.00,
    "adjustedPrice" : 620.00,
    "discount" : 6.5,
    "items" : [
        {
            "id": 1,
            "qty" : 2
        },
        {
             "id": 3,
            "qty" : 1
        }
    ]
}
        */
        let resultStatus = true;
        trackPromise(
         axios.delete("/api-route/api/v1/service/inventory-package/"+id)
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            if(resultStatus){
                message.success("Package "+CommonUtil.status_messages.delete_success)
            } else {
                message.error(CommonUtil.status_messages.error)
            }
         
            dataUpdaterFunc(resultStatus)
        }
    
        )
        );

    }

    static getPackageById = (id,dataUpdaterFunc=()=>{}) => {

        /*

        {
    "id": 1,
    "name": "Test Inventory Package1",
    "code": "P0002",
    "total": 645.0,
    "adjustedPrice": 620.0,
    "discount": 6.5,
    "items": [
        {
            "qty": 2,
            "name": "Parvo and Distemper titer (Cornell)",
            "code": "PDT"
        },
        {
            "qty": 1,
            "name": "Thyroid MSU-TT3, TT4, FT4, FT3, FT4ED, TSH, T4AA, T3AA, TgAA",
            "code": "L722"
        }
    ]
}


        */


        let resultantData = {};
        trackPromise(
         axios.get("/api-route/api/v1/service/inventory-package/"+id)
        .then(response => resultantData=response.data)
        .catch(err => console.log("Error occurred at getPackageById API: " + err))
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }

    static getAllPackages = (dataUpdaterFunc=()=>{}) => {

        /*

            [
    {
        "id": 1,
        "name": "Test Inventory Package1",
        "code": "P0002",
        "total": 645.0,
        "count": 2
    },
    {
        "id": 2,
        "name": "Test Inventory Package1",
        "code": "P0002",
        "total": 645.0,
        "count": 2
    }
]

        */


        let resultantData = [];
        trackPromise(
         axios.get("/api-route/api/v1/service/inventory-package")
        .then(response => resultantData=response.data)
        .catch(err => console.log("Error occurred at getAllPackages API: " + err))
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }

    static getItemsForPackage = (packageId,dataUpdaterFunc=()=>{}) => {

        /*

          [
    {
        "id": 49,
        "price": 57.0,
        "qty": 1,
        "type": 2,
        "name": "IV Catheterization",
        "code": "H2107"
    },
    {
        "id": 153,
        "price": 35.0,
        "qty": 1,
        "type": 2,
        "name": "Cremation Services - standard",
        "code": "P434"
    },
   
]

        */


        let resultantData = [];
        trackPromise(
         axios.get(`api-route/api/v1/service/inventory-package/${packageId}/items`)
        .then(response => resultantData=response.data)
        .catch(err => console.log("Error occurred at getItemsForPackage API: " + err))
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }




}