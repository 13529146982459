import axios from 'axios';
import { trackPromise} from 'react-promise-tracker';
import CommonUtil from '../components/util/CommonUtil';
import { message} from 'antd';
export default class LoginServices {
    static registerUser = async (userObject) => {
        /*
            {
    "name":"arun",
    "email":"a@a.com",
    "phone":"1234567890",
    "address1": "1212dsaw dsd",
    "address2": " ",
    "city": "Atlanta",
    "stateId": "13",
    "zip": "30041",
    "timeZoneId": "1",
    "password":"23342wssad"
}

        */
        return await axios.post("/api-route/api/v1/service/register", userObject);
    }
    
    static loginUser = async (userObject) => {
        const params = new URLSearchParams()
        params.append('username', userObject.username);
        params.append('password', userObject.password);

        const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        }
        return await axios.post("/api-route/login", params,config);
    }

    static logout = async () => {
        return await axios.get(`/api-route/logout`);
    }
    
    static getUserProfile = async () => {
        return await axios.get(`/api-route/api/v1/user`);
    }

    static updatePwd = async (userObject) => {
        return await axios.post("/api-route/api/v1/user/update/pwd",userObject);
    }

    static updateUser = async (userObject) => {
        return await axios.post("/api-route/api/v1/user/update",userObject);
    }

    static getLoggedInUserDetails = ( dataUpdaterFunc = () => {
    }) => {
        let resultantData = null;
            // {
            //     "userId": 15,
            //     "firstName": "Dr. Carla",
            //     "lastName": "Politte",
            //     "doctor": true,
            //     "license": "010122",
            //     "address1": "",
            //     "address2": "",
            //     "city": "",
            //     "stateId": 13,
            //     "zip": "",
            //     "email": "polittedvm@gmail.com",
            //     "permission": "DR",
            //     "status": "A"
            // }

        


        trackPromise(
            axios.get("/api-route/api/v1/service/usr-me")
                .then(response => resultantData = response.data)
                .catch(err => console.log("Error occurred at getLoggedInUserDetails API: " + err))
                .finally(() => {
                    dataUpdaterFunc(resultantData);
                })
        );
    }

    static getLoggedInUserBranchDetails = ( dataUpdaterFunc = () => {
    }) => {
        // dataUpdaterFunc({});
        let resultantData = []
            // [
            //     {
            //         "branchId": 1,
            //         "orgId": 1,
            //         "name": "Trusted Friend Animal Clinic",
            //         "email": "trustedfriendvet@gmail.com",
            //         "phone": "+1 (404) 907-1404",
            //         "mobile": "",
            //         "address1": "5975 Roswell Rd., Ste C309",
            //         "address2": "",
            //         "city": "Sandy Springs",
            //         "stateId": 13,
            //         "zipCode": "30328",
            //         "logo": null,
            //         "tzId": null,
            //         "createdTime": "2021-11-13T14:00:26",
            //         "updatedTime": "2021-11-13T14:00:26"
            //     }
            // ]

        


        trackPromise(
            axios.get("/api-route/api/v1/service/branch-active")
                .then(response => resultantData = response.data)
                .catch(err => console.log("Error occurred at getLoggedInUserBranchDetails API: " + err))
                .finally(() => {
                    dataUpdaterFunc(resultantData);
                })
        );
    }


    static updateDefaultBranch = (branchId,dataUpdaterFunc=()=>{}) => {
        let resultStatus = true;
        trackPromise(
         axios.post("/api-route/api/v1/service/branch-assign",{branchId})
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            dataUpdaterFunc(resultStatus)
        }

        )
        );

      
    
    }

    static initiateResetPassword = (email,dataUpdaterFunc=()=>{}) => {
        let resultStatus = true;

        trackPromise(
            axios.post("/api-route/reset/pwd",{email})
           .then(response => response.data)
           .catch(err => {resultStatus=false;})
           .finally( () => {
         
               dataUpdaterFunc(resultStatus)
           }
   
           )
           );
    }

    static resetPassword = (pwd,uuid,token,dataUpdaterFunc=()=>{}) => {
        let resultStatus = true;

        trackPromise(
            axios.post(`/api-route/reset-password/${uuid}?token=${token}`,{pwd})
           .then(response => response.data)
           .catch(err => {resultStatus=false;})
           .finally( () => {
        
               dataUpdaterFunc(resultStatus)
           }
   
           )
           );
    }

    static changePassword = (inputData, dataUpdaterFunc = () => { }) => {
        let resultStatus = true;
        let errormessage = "";
        trackPromise(
            axios.put(`/api-route/api/v1/service/usr-pwd`, inputData)
                .then(response => response.data)
                .catch(err => {
                    if (err.status === 400) errormessage = err.data;
                    resultStatus = false;
                })
                .finally(() => {
                    if (!resultStatus) {

                        message.error(errormessage ?? CommonUtil.status_messages.error)
                    } else {
                        message.success("Password "+ CommonUtil.status_messages.update_success)
                    }

                    dataUpdaterFunc(resultStatus)
                }

                )
        );
    }




};