import React from "react";
import AppointmentServices from "../../services/AppointmentServices";


const getHtml=(isSuccess,branchData)=>{

  return(`
  <!DOCTYPE html>
  <html>
  <head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&family=WindSong:wght@400;500&display=swap" rel="stylesheet">
  </head>
  
  <body style="background-color:#f1f5fa">
    <table style="border-spacing: 0px; background-color:#f1f5fa; font-family: 'Poppins', sans-serif; padding-top:12px; padding-bottom:12px; border-collapse: collapse" border="0" width="100%">
      <tbody>
  
        <tr>
          <td style="width: 100%; align-content: center; text-align: center; padding:24px;">
            <div align="center">
              <table style="max-width: 600px; text-align: -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.09); 
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.09);center; border-spacing: 0px; background-color:#ffffff; border-radius: 16px;">
                <tbody>
                  <tr>
                    <td align="center" style="padding:36px 36px 12px 36px">
                      <div style="width:250px">
                        <?xml version="1.0" encoding="utf-8"?>
                        <!-- Generator: Adobe Illustrator 26.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2666.63 1627.89">
                          <defs>
                            <style>
                              .cls-1 {
                                fill: #ebf3fa;
                              }
  
                              .cls-2,
                              .cls-3 {
                                fill: #008489;
                              }
  
                              .cls-2 {
                                opacity: 0.21;
                              }
  
                              .cls-3 {
                                opacity: 0.11;
                              }
  
                              .cls-4 {
                                fill: #ed7d2b;
                              }
  
                              .cls-5 {
                                fill: #f1a34f;
                              }
  
                              .cls-6 {
                                fill: #e07f20;
                              }
  
                              .cls-7 {
                                fill: #bc6211;
                              }
  
                              .cls-8 {
                                fill: #ffdabb;
                              }
  
                              .cls-9 {
                                fill: #c0d8fb;
                              }
  
                              .cls-10 {
                                fill: #dfebfd;
                              }
  
                              .cls-11 {
                                fill: #b7d3f3;
                              }
  
                              .cls-12 {
                                fill: #ffb27d;
                              }
  
                              .cls-13 {
                                fill: #9db0f4;
                              }
  
                              .cls-14 {
                                fill: #233862;
                              }
  
                              .cls-15 {
                                fill: #b5453c;
                              }
  
                              .cls-16 {
                                fill: #93312c;
                              }
  
                              .cls-17 {
                                fill: #aec3ff;
                              }
  
                              .cls-18 {
                                fill: #ed985f;
                              }
  
                              .cls-19 {
                                fill: #2c3a64;
                              }
  
                              .cls-20 {
                                fill: #e58435;
                              }
  
                              .cls-21 {
                                fill: #f2ccaa;
                              }
  
                              .cls-22 {
                                fill: #f9ddbf;
                              }
  
                              .cls-23 {
                                fill: #163560;
                              }
  
                              .cls-24 {
                                fill: #fca56b;
                              }
  
                              .cls-25 {
                                fill: #fff;
                              }
  
                              .cls-26 {
                                fill: #dfebfa;
                              }
                            </style>
                          </defs>
                          <g id="Layer_2" data-name="Layer 2">
                            <g id="Слой_1" data-name="Слой 1">
                              <path class="cls-1" d="M163.53,1626.32s-327.63-356-55.41-741.35C349.65,543,520.54,725,751.75,387.05c553.7-809.39,1959-209.25,1772.85,520.77-90.06,353.27,274.11,366.11,88.55,719.24Z" />
                              <path class="cls-2" d="M2181.72,1068.1C2137,1016.15,2045.21,1083,2045.21,1083s-18.43,137.16-37.14,149.17-7.14-120.35-7.14-120.35c-40.33,14.71-116,109-116,109s64.5,118.88,43.42,125.91-51.75-108.18-51.75-108.18c-32,29-46,89.21-46,89.21-9.62,91.7,108.62,99.31,108.62,99.31s21.47,41.69,18.55,102.67h0s-1.61,11.77-.32,29.85l10.17-4.86c-.47-3.13-.82-6.2-1-9.22a0,0,0,0,1,0,0,.53.53,0,0,0,0-.13c0-1.91-.12-4.62-.17-7.94v0s0,0,0,0c.19-7.57.14-13.07-.08-17v0c.17-23.55,1.68-54.84,8.13-64.34a21.61,21.61,0,0,1-4.4-3.62l4.4,3.62c35.26,29,125.27-6.44,169-39.58s16.06-36.72-20.68-46.42-88.64,22.31-109,21.34-.87-21,32.65-34,65.15,11.62,119.76-8.38,47.41-137.28,47.41-137.28c-30.71,28.44-104.89,39.47-113.32,28.23s87.91-55.8,101-66.67S2226.44,1120.06,2181.72,1068.1Z" />
                              <path class="cls-2" d="M498,918.27c11.78-114.58,202.54-110.56,202.54-110.56S857.67,979.17,895,977.76,789.28,804.94,789.28,804.94c69.74-18.44,264.53,38.9,264.53,38.9s25.19,225.88,61,215.33-32.51-198.87-32.51-198.87c71.94,9.36,149,78.9,149,78.9,101.31,117.22-54.43,241.26-54.43,241.26s10.42,78.09,73,159.39h0s13.52,14.69,29.11,40.87l-18.7,3.06q-3.52-7.15-7.44-13.69a.18.18,0,0,0-.05-.07l-.12-.19c-1.75-2.68-4.27-6.49-7.38-11.12v0l0,0c-7.53-10.25-12.74-17.88-16.16-23.45l0,0c-22.85-32.3-55-74-73-80.91a36.19,36.19,0,0,0,2.6-9.22q-1.23,4.41-2.6,9.22C1135.32,1328.07,977.18,1365.66,885,1362s-57.39-35.21-16.06-83.87,143.67-54.34,170.77-75.2-19-29.76-77.63-15.47-78.69,78.58-173.2,103.42S591.73,1147,591.73,1147c69.66,9.73,182.55-46.27,183.38-69.88s-174.8,7.46-203.36,5.08S486.23,1032.86,498,918.27Z" />
                              <path class="cls-2" d="M1865.32,305.1c-85.26-67.41-208,64.27-208,64.27s12.63,219.08-12.88,243.4S1597,427.57,1597,427.57c-58.35,35.05-147.71,204.53-147.71,204.53S1585.55,797.74,1554.84,815,1441.7,662.21,1441.7,662.21C1400.71,717,1397,814.93,1397,814.93c12.64,145.64,199,121.88,199,121.88s46,58.43,59.8,154.24h0s1,18.81,8.49,46.57L1678.7,1127c-1.67-4.72-3.14-9.4-4.35-14a.1.1,0,0,1,0-.09,1.17,1.17,0,0,1,0-.2c-.66-3-1.59-7.15-2.67-12.3v0c-2-11.84-3.71-20.38-5.22-26.35,0,0,0,0,0-.06-6.82-36.71-13.89-85.88-6.71-102.6a34.63,34.63,0,0,1-8-4.32l8,4.32c63.61,34.49,193.07-47.76,251.17-112.53s13.94-62-46.16-66-131.28,61.43-163.22,66-7.66-32.4,40.61-62.69S1847,794.57,1926,747s32.45-228,32.45-228c-39.23,53.52-151.38,93-167.9,78.07s120-113.34,137.22-134.22S1950.57,372.51,1865.32,305.1Z" />
                              <path class="cls-3" d="M899.52,1458.58c-2.41-49.56,79.08-60.21,79.08-60.21s78,62.83,93.82,59.81-56.21-66.74-56.21-66.74c28.5-12.37,115.16-.58,115.16-.58s25.37,94.56,39.94,87.74-26.73-82.58-26.73-82.58c31.24-.67,68.57,23.94,68.57,23.94,50.74,43.35-7.54,106.26-7.54,106.26s9.5,32.58,41.41,63.15h0a115.34,115.34,0,0,1,15,15.52l-7.76,2.51q-2-2.82-4.06-5.35l0,0-.06-.07c-.92-1-2.23-2.48-3.86-4.26h0c-3.88-3.88-6.59-6.79-8.41-8.94v0c-11.83-12.27-28.21-28-36.33-29.72a15.76,15.76,0,0,0,.51-4.1c-.16,1.31-.33,2.67-.51,4.1-4.07,32.78-69,59-108.45,63.43s-26.73-11.28-12.28-34.68,57.66-32.45,67.85-43.09-10-11.44-34.06-1.56-28.42,38.57-67.06,55.27-93.28-48.45-93.28-48.45c30.29-.37,74.73-31.53,73.56-41.64s-74,14.5-86.27,15.34S901.93,1508.14,899.52,1458.58Z" />
                              <path class="cls-4" d="M1006.61,616.93c-32.16,16.54-85.35,31.47-104.14,73.85-6.67,15-3.08,67.67,19.48,64.6,12.4-1.69,16-18.34,16.67-33a39.16,39.16,0,0,1,22.79-33.52l146.8-64.39-27.63-56.81S1020.73,609.68,1006.61,616.93Z" />
                              <path class="cls-5" d="M2461.9,1623.17H1573c0,10.61-160,0-160,0s11.28-54.71,37.93-70.09c6-3.48,50.45,7.12,61.33,6.65-4.6-2.88-98.68-32-77.6-176l-50.21,232a8.82,8.82,0,0,1-8.69,7.39H1179a8.8,8.8,0,0,1-8.67-7.19c-2.95-15.64-7.82-52.82,7.69-71.72,12.24-14.91,50.74-11.54,78.71-6.7,6.29,1.08,12,2.24,16.84,3.29a8.82,8.82,0,0,0,10.4-10.93s-14.63-43-41.26-102.68S1177.47,1291,1129.4,1224c-9.63-13.42-19.62-26.46-30-38.93-37.18-44.72-62.37-152.21-29.83-199.67,4.26-6.21,23.44-26.72,16.55-23.68-14.19,6.26-24.42,16.6-31.43,26.22-4.91,6.75-15.61,3.42-15.89-4.93-3.92-112.08,80.74-207.88,114-256.75,10.82-15.92,16.19-26.87,11.24-31.77-21.36-21.23-115.7,32.6-158.32,31.08a113.47,113.47,0,0,1-55.8-16.87,8.81,8.81,0,0,1,.59-15.31C975,680.66,1012,665.05,1041.22,644c14.7-10.59,27.88-29.23,35.55-45.45,5.81-12.31,3.81-27.35,3.81-27.35-33,28.21-76.62,51.48-113.89,64.18-24.83,8.46-46.84,12.25-61.06,9.7-36.67-6.55-59.76-40.23-72.76-67.62a227.46,227.46,0,0,1-11.53-29.48,8.79,8.79,0,0,1,4.81-10.64c6-2.68,15.4-6.95,27-12.27,53.7-24.7,113.25-44.26,123.9-61.33a196.84,196.84,0,0,0,10.35-19.29l0-.08c15.93-33.23,31.4-77,76-112.41,69.3-54.92,140-65.41,169.46-67.3,9-.59,14.22-.36,14.28-.36,46-1.84,80.07,5,105.49,18.19h0c98.49,51.2,66.46,198.47,87.09,311q1.2,6.57,2.66,13c8.49,37.18,117,138,121.35,199.25,8.19,116.88,95.64,127.8,289,246.07,111.8,68.4,119.79,253.67,109.72,394.54A67.88,67.88,0,0,0,2019,1518c47,7.8,99.33,11.09,147.56,2.12,132.29-24.61,160-249.17,80-283,0,0,135.77-40,201.7,64.6S2514.2,1572.38,2461.9,1623.17Z" />
                              <path class="cls-4" d="M1265.44,296.69c-38.94,0-72.19,29.66-78.4,70.07-9.41,61.23-18.74,149.6-6.93,210.38,17.61,90.66,95.82,123.85,146,97,92.71-49.63,50.36-241.46,24.59-331-8-27.62-32.22-46.48-59.68-46.48Z" />
                              <path class="cls-6" d="M1054.22,453.94a9.23,9.23,0,0,1-6.06-16.2c1.11-1,27.65-23.49,70.72-13.29a9.23,9.23,0,1,1-4.25,18c-34-8.05-54.16,9.09-54.36,9.26A9.21,9.21,0,0,1,1054.22,453.94Z" />
                              <path class="cls-7" d="M832.87,577.41a226.88,226.88,0,0,1-11.53-29.47,8.81,8.81,0,0,1,4.81-10.65c6-2.67,15.4-6.94,27-12.26C853.12,525,860.68,568,832.87,577.41Z" />
                              <path class="cls-8" d="M2461.9,1623.17H2305.51c178.53-36.7,141.11-173.59,141.11-173.59-18.44,33.83-121.49,40-121.49,40s69.21-64.6,63.06-159.94c-5-77.5-95.42-90.13-129.11-92.14-7.76-.46-12.52-.36-12.52-.36s135.76-40,201.7,64.6S2514.2,1572.38,2461.9,1623.17Z" />
                              <path class="cls-5" d="M1352.67,282.45s87,22.94,105.44,92.14c0,0-11.54-11.22-19.75-14.64,0,0,22.42,19.14,19.75,67.67s-30,125.78-15.66,178.75l-54.67-95Z" />
                              <path class="cls-5" d="M987.37,444.41s25.09-63.27,0-86.85c0,0,17.46-5.13,26.17,0,0,0,3.08-48.19,64.6-69.73s154.77-23.22,154.77-23.22L1072,384.21Z" />
                              <rect class="cls-9" x="2103.61" y="407.43" width="170.09" height="165.01" rx="12.17" />
                              <rect class="cls-10" x="2110.98" y="414.57" width="155.35" height="150.72" rx="11.11" />
                              <path class="cls-11" d="M2185.14,429.64a3.52,3.52,0,1,1,3.52,3.52A3.52,3.52,0,0,1,2185.14,429.64Z" />
                              <path class="cls-11" d="M2213.57,434.61a3.52,3.52,0,1,1,1.36,4.8A3.51,3.51,0,0,1,2213.57,434.61Z" />
                              <path class="cls-11" d="M2237.92,454a3.52,3.52,0,1,1-1.05,4.87A3.51,3.51,0,0,1,2237.92,454Z" />
                              <path class="cls-11" d="M2250,483.92a3.52,3.52,0,1,1-3.53,3.52A3.52,3.52,0,0,1,2250,483.92Z" />
                              <circle class="cls-11" cx="2241.34" cy="521.17" r="3.52" />
                              <circle class="cls-11" cx="2219.21" cy="543.35" r="3.52" />
                              <path class="cls-11" d="M2185.14,552.29a3.52,3.52,0,1,1,3.52,3.52A3.52,3.52,0,0,1,2185.14,552.29Z" />
                              <path class="cls-11" d="M2163.64,434.61a3.53,3.53,0,1,0-1.37,4.8A3.52,3.52,0,0,0,2163.64,434.61Z" />
                              <path class="cls-11" d="M2139.28,454a3.52,3.52,0,1,0,1,4.87A3.52,3.52,0,0,0,2139.28,454Z" />
                              <circle class="cls-11" cx="2127.22" cy="487.44" r="3.52" />
                              <path class="cls-11" d="M2133.38,518.67a3.52,3.52,0,1,0,5,0A3.52,3.52,0,0,0,2133.38,518.67Z" />
                              <path class="cls-11" d="M2155.5,540.86a3.52,3.52,0,1,0,5,0A3.53,3.53,0,0,0,2155.5,540.86Z" />
                              <path class="cls-11" d="M2187.71,487.44a3.53,3.53,0,1,1,3.52,3.52A3.52,3.52,0,0,1,2187.71,487.44Z" />
                              <rect class="cls-11" x="2190.75" y="443.42" width="0.96" height="44.02" />
                              <rect class="cls-11" x="2187.62" y="476.78" width="29.67" height="2.89" transform="translate(174.78 1462.11) rotate(-38.13)" />
                              <path class="cls-12" d="M1635.49,1135.59a8.86,8.86,0,0,1-3,3.62c-1.75,2.23-3.69,5-4.1,6.67-.79,3.22-4.68,3.73-5.6.72a6.88,6.88,0,0,1-.15-3.17c-6.35,1.43-15.75,2-29.67,0,0,0-10.81-1-2.09-8.56,5.82-5,9.79-4.25,18.12-11.21s19.18-9.5,26.25-5.73l9,.78L1647,1134Z" />
                              <path class="cls-13" d="M1742.4,836.08s69.37,69,20.57,192.32c-13.17,33.3-120.68,109.53-120.68,109.53s-12.45-11.25-9.09-21.9c0,0,70.48-96.49,80.68-101.08,9-4.07-2.72-112.74,3.27-139.75S1742.4,836.08,1742.4,836.08Z" />
                              <path class="cls-14" d="M1995.22,1550.69s15.09,13.77,12.15,23.55-16.56,16.31-18.41,28.27-9.2,18.49-15.82,18.49h-31.59s-4.87-8.42,6.9-11,16.22-16.59,15.49-29.64S1965,1550,1965,1550l25-5.8Z" />
                              <path class="cls-15" d="M1995.22,1542.05c-2.76,8.16-33.13,20.11-33.13,20.11s-16.56-26.08-69.56-119.6c-4.56-8-9.33-16.89-14.24-26.31-52-100-119.37-266.16-119.37-266.16l128.65-35.87s6.06,51.64,7.17,77.73c.67,16,9.74,50,21.26,79.78,7.33,18.94,15.66,36.13,23.46,45.8C1975,1361.54,1995.22,1542.05,1995.22,1542.05Z" />
                              <path class="cls-16" d="M1916,1271.73c-8.09,41-22.93,109.7-37.71,144.52-52-100-119.37-266.16-119.37-266.16l128.65-35.87s6.06,51.64,7.17,77.73C1895.41,1207.9,1904.48,1242,1916,1271.73Z" />
                              <path class="cls-14" d="M1810.08,1586.31s2.71,31.34-3.91,34.6l-84.79-.17s0-9.79,13.62-12,37.54-10.15,41.59-27.91S1810.08,1586.31,1810.08,1586.31Z" />
                              <path class="cls-15" d="M1738.5,1143.57s4.47,54.94,9.73,121c7.14,89.71,15.73,199.9,16.58,220.78,1.47,36.25,7.37,94.23,7.37,94.23s14,13.78,42,8c0,0,24.93-82.77,32-170.35l.33-4.33c6.63-89.15,16.93-276.89,16.93-276.89Z" />
                              <path class="cls-12" d="M1783.7,823.92s-44.42,40.68-77,36c0,0,6.53-14.46,13.06-26.22-2.08-6.05-3.78-11.33-5-15.84a150.55,150.55,0,0,1-4-16.36l18.14-19.69,17.18-18.8C1751.62,807.25,1783.7,823.92,1783.7,823.92Z" />
                              <path class="cls-17" d="M1918,1211.57c-56.26,52.77-185.8,61.14-186.89,60.49-6.5-26.79-14.91-71.34-22.24-112.63-3-17.23-5.93-33.87-8.38-48.44-4.9-28.83-8.21-49.49-8.28-50.14,0,0-.21-1.57-.57-4.22-.56-4.05-1.44-10.65-2.4-18-1-7.86-2.16-16.59-3.06-24-.34-2.69-.64-5.2-.89-7.43,0-.13,0-.24,0-.37-.48-4.9.07-16.27,1.36-30.54.41-4.6.9-9.5,1.45-14.58,0-.13,0-.24.05-.38,3.38-30.51,9.17-67.46,15.31-85.24,3.2-9.15,14.49-36,14.49-36,11.08.4,18.33-4,28.78-11.38,11.45-8,23.78-16.6,26.2-15.3,18.15,9.26,33.43,34.16,44.77,67.31C1824.21,899.81,1920.83,1208.93,1918,1211.57Z" />
                              <path class="cls-18" d="M1749.16,779.79s-9.62,27-34.41,38.1a150.55,150.55,0,0,1-4-16.36l18.14-19.69Z" />
                              <path class="cls-12" d="M1701.18,802.34s52.72.13,52.46-32.23,6.36-54.12-27.31-55.58-40.11,11-42.69,21.81S1687.46,801.47,1701.18,802.34Z" />
                              <path class="cls-19" d="M1762.28,704.87c.38-.61,1.39-1.22,3.76-1.73a9.78,9.78,0,0,0-4.67-.54c-1-3.25-3.76-8.86-9.72-9.37a7.28,7.28,0,0,1,4.32,4.62c-7.73-5.74-22.75-8.42-61.62,1-51.22,12.45-28.77,42.92-21.42,45.79s22.52-10.7,35.19-11.72,18,16.68,22.61,18.48,2.27-2.32,7.6-5.84,7.4,5.9,6.74,12.36,8.54,13.35,8.54,13.35l6.6-9.88c6.59-9.87,8.25-47.46,3.37-54.63Z" />
                              <path class="cls-13" d="M1773.07,813.37s-7-13.1-11.15-14c0,0-52.05,21.17-60.46,83.82C1701.46,883.15,1716.59,843.1,1773.07,813.37Z" />
                              <path class="cls-13" d="M1766.39,923.68c-.55,1.46-32.49,84.72-77.11,115-1-7.86-2.16-16.59-3.06-24-.34-2.69-.64-5.2-.89-7.43,0-.13,0-.24,0-.37-.48-4.9.07-16.27,1.36-30.54,8.25-11.93,14.8-20.48,14.8-20.48Z" />
                              <path class="cls-12" d="M1498.83,1038.4s-19.23-14.88-25.19-14.45-3.6,12,.12,18.39c4.17,7.23,9.75,13.83,15.9,14a52.35,52.35,0,0,0,11.24-1Z" />
                              <path class="cls-12" d="M1500.12,1037.39s-3.81-6.37-6-6.77-9.7.31-11.31,1.2,12.1,9.83,14.65,9.1A3.92,3.92,0,0,0,1500.12,1037.39Z" />
                              <path class="cls-17" d="M1774.85,851.24s19.37,105.37-101.47,185.9c-32.63,21.74-176.94,23.27-176.94,23.27s-4.18-17.9,5.49-25.38c0,0,123.62-42.86,135.66-40.63,10.68,2,67.89-103.15,90.12-123.72S1774.85,851.24,1774.85,851.24Z" />
                              <path class="cls-18" d="M612.78,1147.3c-6.78-21.8-36.3-25.89-48.18-6.39a24.21,24.21,0,0,0-3.61,12.15c-.65,25.56,49.75,124.48,56.44,133.23,12.18,15.93,85.7,54.24,85.7,54.24l15.63-2.27a115.4,115.4,0,0,1-18-14.68c-18.89-18.86-46.43-49.52-51.47-53.77C640.65,1262.52,625.67,1188.68,612.78,1147.3Z" />
                              <path class="cls-15" d="M634.12,1022.26c.41-1.64-35.66,16.3-37.49,18.33-16.92,18.77-12.09,43.26-2.41,43.49,6.21.15,5,10.47,5,10.47l69.16-29.25S660.06,1016.74,634.12,1022.26Z" />
                              <path class="cls-15" d="M642.64,1026.05s2.47-26.94-27.63-26.94c0,0,9.12,2.47,13.84,7.42,0,0-19.2-11.27-40.1,6.05s-7.91,60.76-14.26,69-31.1,9.9-28.07,36.57,78.52,60.07,73.67,82.13c0,0,29.59-43.5,8.44-63.35,0,0,10.31,9.72,11.36,21.26,0,0,2.8-22.54-11.55-29.75-12.69-6.38-23.09-29.72-22-33.89S642.64,1026.05,642.64,1026.05Z" />
                              <path class="cls-5" d="M558.08,1608.61l-15-2.2s-50.33-40-57.82-114.61a134.41,134.41,0,0,1-.36-23.07,4,4,0,0,0-.52-2.55l0,0c-1.61-2.47-5.62-8.65-11-17.45s-14.51-25-24.58-43.44c-18.15-33.11-39.27-72.71-46.52-85.32-23.08-40.2-12-67.23-12-67.23s64.41-6.37,82.06-21.81l6.36,9.8s13.95,61.78,13.29,62.06l2.1,6.42c11,34,23.68,76.48,30.59,111.08,3.88,19.47,5.94,36.43,4.83,48-.38,4,.08,10.06,1.14,17.58C536.9,1530.18,558.08,1608.61,558.08,1608.61Z" />
                              <path class="cls-14" d="M589.48,1627.55H527s-1.5-9.52,1.76-16.74c3.13-6.93,7.58-7.15.57-18,4,4.09,16.72,5.78,25.75,4.56,1.11,1.8,7.13,13.83,10.83,16.48C570,1616.77,588.22,1615.73,589.48,1627.55Z" />
                              <path class="cls-20" d="M494.11,1309.19c-5.46,30.08-18.34,80.15-45.33,96.08-18.15-33.11-39.27-72.71-46.52-85.32-23.08-40.2-12-67.23-12-67.23s64.41-6.37,82.06-21.81l6.36,9.8s13.95,61.78,13.29,62.06Z" />
                              <path class="cls-5" d="M450.32,1257.36l.21-11.68c-23.27,2.94-80.23-27.8-80.23-27.8s-24.34,16.19-27.67,62.43c-1.63,22.56-11.92,118-13.06,146.5-.41,10.33-.55,17.7-.6,20.65a0,0,0,0,1,0,0,4.1,4.1,0,0,1-1,2.4,132.92,132.92,0,0,0-12.61,19.33C279.87,1535.28,298.44,1625,298.44,1625l8.25-2.1s30-93,49.64-133.3c3.33-6.83,6.35-12.14,8.88-15.21,7.38-9,15.16-24.18,22.83-42.49,14.49-34.62,28.56-80.31,38.6-115.68C427.35,1316.39,450.32,1257.36,450.32,1257.36Z" />
                              <path class="cls-12" d="M586.48,1179.42c-38-8.73-13.74-62.18-11.53-61.24,5.27-1.07,16.84-10.33,27.2-28.4l1.39,1.21,23.37,20.49s-5.4,8-9.95,15.77c-3.55,6.1-6.57,12.12-6.09,14.19C612.76,1142.92,596.62,1181.75,586.48,1179.42Z" />
                              <path class="cls-13" d="M610.49,1139.72s6.69,13.19,10.26,24.63,12.5,47.79,12.5,47.79-18.42,21-44,19.78c0,0-13.77-21.56-8.92-41S610.49,1139.72,610.49,1139.72Z" />
                              <path class="cls-18" d="M626.91,1111.48s-5.41,8-9.95,15.77c-14.52-9-14.54-26.94-13.42-36.25Z" />
                              <path class="cls-12" d="M627.55,1121.74s-35.26-19.62-22.71-41.4,16.47-39,39.52-27.36,22.59,22.42,20.17,30.7S637.05,1126.28,627.55,1121.74Z" />
                              <path class="cls-15" d="M670.48,1071c-1.61-9.66-6.52-10.59-10-9.94-13-21.54-34.3-13.13-34.3-13.13a48,48,0,0,1-27.8,16.69s4.1,7.62,21,12.33c5.15,1.44,11,.57,16.63-1.33a94.25,94.25,0,0,1-23.93,9c22.22,2,43.36-18.28,45.56-20.45,1,3.15,2.82,9.74,1.74,14.56-1.46,6.54-7.27,18.4-3.72,19.94C655.67,1098.67,672.91,1085.48,670.48,1071Z" />
                              <path class="cls-14" d="M348.1,1627.55H285.66s-1.5-9.52,1.76-16.74c3.12-6.93,8-10.08,6.85-18.46,4,4.09,10.43,8.66,19.46,7.45,1.1,1.79,7.14,11.39,10.83,14C328.64,1616.77,346.84,1615.73,348.1,1627.55Z" />
                              <path class="cls-17" d="M591,1221.79c-8,2.81-19.31,4.35-30.85,5.6-16.42,1.79-33.37,3-42.25,6.56a56.62,56.62,0,0,0-11.84,6.46h0a3.08,3.08,0,0,0-.35.27,37.56,37.56,0,0,0-4.9,4.22,27,27,0,0,0-5.73,8.5c-3.25,8,1.06,30.35,3.82,42.54a11.08,11.08,0,0,1-5.67,12.3q-9.36,4.83-18.4,8.93l-1,.43c.05-1.24.07-2.47.07-3.68a114.17,114.17,0,0,0-1.05-15.41c-1.49,8.49-7.08,17.66-12.29,24.72-83.8,33.62-142.34,19-141.38,2.26,3.3-58.16,42.52-107.62,42.52-107.62,73.14-85.06,221.09-107.45,221.09-107.45s7.09-.85,15.64,9.42c12.3,14.81,12.91,18.66,12.91,18.66S614.6,1213.55,591,1221.79Z" />
                              <path class="cls-13" d="M560.11,1227.39c-16.42,1.79-33.37,3-42.25,6.56-3.63-54.23,22-95,22-95S556.51,1206.87,560.11,1227.39Z" />
                              <path class="cls-13" d="M498.86,1296a11.08,11.08,0,0,1-5.67,12.3q-9.36,4.83-18.4,8.93c-.32-1-.61-2.1-.88-3.25a56.54,56.54,0,0,1-1.05-15.41c1.39-25.74,18.93-45.23,27.91-53.61a27,27,0,0,0-5.73,8.5C491.79,1261.46,496.1,1283.76,498.86,1296Z" />
                              <path class="cls-13" d="M582.8,1110.43s6.45-8.14,10.8-7.88c0,0,17.61,24.65,17.75,36C611.35,1138.51,589.39,1109.29,582.8,1110.43Z" />
                              <path class="cls-18" d="M751.73,1349s-9.36.16-11.39-.13-4.63-1.15-7.3-1.24-5,.75-7.49,1.26a6,6,0,0,1-1.12.11H708.91a9.37,9.37,0,0,1-3.27-3.49c-.73-1.19-.83-4-2.51-5-9.4-5.24,15.63-2.27,15.63-2.27s15.71-.29,17.16.79C738.5,1341,752.31,1348.22,751.73,1349Z" />
                              <path class="cls-18" d="M708.91,1349s9.53,2.75,12.12,4.52l2.74,1.72s1.58-1.76-.91-3.72c-1.32-1-5-4.07-5.07-7.54S706.91,1343.51,708.91,1349Z" />
                              <path class="cls-21" d="M1119.66,1499c-14.33-22.77-49.15-21.51-73.12-17.4h0c-1.59.27-3.12.56-4.6.84a182.92,182.92,0,0,0-20,5L815,1369.2l-13.22-7.56s-13.49-72.91-55.4-77.29c-.79-.08-1.58-.14-2.39-.17-39.91-1.63-30,53-28,62.7.2.95.32,1.46.32,1.46s-2.1,0-5.45.29c-13.51,1.23-47.35,7.65-45.12,41.29,2,30.81,52.89,36.62,79.77,37.53a70.32,70.32,0,0,1,32.48,9.26l184.29,105.4a84.89,84.89,0,0,1,29.44,28c14.54,22.78,43.14,59.3,70.66,46.63,1.65-.76,3.21-1.55,4.7-2.4h0c33.46-18.77,23-53.91,23-53.91S1142.52,1535.35,1119.66,1499Z" />
                              <path class="cls-22" d="M1119.66,1499c-14.33-22.77-49.15-21.51-73.12-17.4h0c-2.53.73-4,1.2-4,1.2l-.63-.36a182.92,182.92,0,0,0-20,5L815,1369.2l-6.36-3.64a17.8,17.8,0,0,1-8.48-11c-4.65-18.46-20-66.62-53.78-70.16-20.21,15-9.37,59.31-9.37,59.31a80.25,80.25,0,0,0-21,3.22c-1.69.5-3.41,1.08-5.13,1.75-13.07,5.12-25.86,15.51-24.47,36.61,2,30.26,51.1,36.4,78.31,37.47,3.69.14,7,.19,9.68.2a31.85,31.85,0,0,1,15.66,4.2l199.35,114h0a55.38,55.38,0,0,1,19.89,19.34c2.41,4,5.41,8.67,8.88,13.61,12.15,17.3,15.08,44.66,48.92,40.29h0c33.46-18.77,23-53.91,23-53.91S1142.52,1535.35,1119.66,1499Z" />
                              <path class="cls-12" d="M577.35,1128.44s-41.15,4.07-44.45,30.8,39.45,135.65,45.58,145.51c11.14,18,76.77,79.49,76.77,79.49l12.1-7.33s-46.55-78.81-53.76-86.19C602.27,1279.14,591.28,1137.7,577.35,1128.44Z" />
                              <path class="cls-17" d="M598.43,1179.52s4.23-68.94-48.54-52.27,8.19,135.25,8.19,135.25,31-1.53,42-28.55Z" />
                              <path class="cls-12" d="M667.35,1376.91s6.81,6,12,7.33,11.07,16.12,12,18.24-5-1-7.3-4.56-4-5-4.66-4.24.42,10.68,4.34,16.17,6.28,10.37,3.46,10.05-19.63-4.39-23.09-14.6-.92-10.56-8.86-21.06S667.35,1376.91,667.35,1376.91Z" />
                              <path class="cls-12" d="M864,1202.75a7.59,7.59,0,0,1,3.91,1.19c2.38.65,10,1.41,11.47,1.17,2.84-.46,2.17,3.14.08,4.89-.7.58-3.9.18-4.85.4,3.29,4.6,6.88,11.95,10,23.74,0,0,2.83,9-6.13,4.57-6-3-6.7-6.41-15.08-10.72s-14.07-12.16-13.44-19.09l-2.42-7.48,11.22-7.37Z" />
                              <path class="cls-13" d="M871.59,938.5s-49.79,34.81-73.24,134.65c-7.11,30.27,46.58,133.7,46.58,133.7s14.15-3.41,16-12.93c0,0-15.16-84.14-19.85-99-2.6-8.22,48.68-54.4,55.32-77.48S871.59,938.5,871.59,938.5Z" />
                              <path class="cls-23" d="M804.94,1592.14s2.08,33.45,8.55,33.93c.86.06,32.82.06,32.82.06l40.8-.23s-.26-9.83-12.19-11.91-31.89-10.46-39.11-26.23C828.21,1571.18,804.94,1592.14,804.94,1592.14Z" />
                              <path class="cls-5" d="M802.59,1594.46s12.4,4.15,22.09,4.15a81.18,81.18,0,0,0,20.62-2.94s2.33-22.58,15.18-72.34c6.74-26.08,16.36-59.61,30-101.28,37.84-115.16,49.35-182.52,50.4-188.9.06-.34.08-.5.08-.5l-.12-.06L824.54,1181.5s14.38,158.72-4,219.63a257.37,257.37,0,0,0-6.66,29.19C802.32,1496.49,802.59,1594.46,802.59,1594.46Z" />
                              <path class="cls-20" d="M813.91,1430.32l46.57,93c6.74-26.08,16.36-59.61,30-101.28,37.84-115.16,49.35-182.52,50.4-188.9,0-.19,0-.37,0-.56L824.54,1181.5s14.38,158.72-4,219.63A257.37,257.37,0,0,0,813.91,1430.32Z" />
                              <path class="cls-23" d="M895.82,1592.14s2.07,33.45,8.55,33.93c.86.06,32.82.06,32.82.06l40.8-.23s-.27-9.83-12.19-11.91-31.89-10.46-39.12-26.23C919.08,1571.18,895.82,1592.14,895.82,1592.14Z" />
                              <path class="cls-5" d="M961.79,1185.24s1.51,102.85-16.29,229.13-11.76,179.88-11.76,179.88-12.22,4-17.16,4.36c-12.57,1-25.46-2.94-25.46-2.94S865.65,1469.34,873,1406.14s-34.88-216.89-34.88-216.89Z" />
                              <path class="cls-12" d="M879.29,944.57,902,959.46l32.82-6.12c-.46-2.65-2.62-13.63-4.59-23.57-.12-.59-.24-1.18-.35-1.75-1.69-8.46-3.15-15.73-3.15-15.73l-30.65-13.53-11.43-5c0,.18.88,9.39,1,10.74C887.79,928.34,879.29,944.57,879.29,944.57Z" />
                              <path class="cls-17" d="M968.57,1189.69c-.47,54.29-4.08,181.29-6.75,183.86-28.27,27.29-152.64-7.48-159.24-37.55s25.07-385.53,73.36-399.73c10-2.94,63.6,14.38,63.6,14.38a340.21,340.21,0,0,1,13.9,50c.68,3.4,1.84,13.62,3.23,27.57,1.37,13.61,3,30.78,4.54,48.62,1.75,19.7,3.46,40.24,4.84,57.7,1,13.19,1.87,24.62,2.36,32.65C968.62,1170.49,968.66,1178.57,968.57,1189.69Z" />
                              <path class="cls-24" d="M887.94,904.88c6.73,11.4,31.82,21.26,42.28,24.89-.12-.59-.24-1.18-.35-1.75-1.69-8.46-3.15-15.73-3.15-15.73l-30.65-13.53C889.29,898.67,885.17,900.19,887.94,904.88Z" />
                              <path class="cls-12" d="M938.27,910.42s-49.37,15.41-58.49-15.49-21.63-49.71,9.51-60.85,40.77-1.17,46.32,8.4S950.88,905.62,938.27,910.42Z" />
                              <path class="cls-23" d="M933.67,839.51s-3.15,36-10.59,34.39S908.91,871.18,910,882s11.4,23.35-11.51,25.26-55.85-57.17-49.56-68.36C867.08,806.64,959.92,800.13,933.67,839.51Z" />
                              <path class="cls-13" d="M926.4,937.06c-.63-.27-36.75-16.47-41-15.08s-9.49,14.3-9.49,14.3l63.59,14.37S934.53,940.48,926.4,937.06Z" />
                              <circle class="cls-25" cx="1182.86" cy="973.99" r="66.66" transform="translate(-36.84 1901.43) rotate(-76.72)" />
                              <circle class="cls-26" cx="1177.78" cy="977.17" r="42.27" />
                              <path class="cls-25" d="M1105.17,1287.21c-11.67,0-23.25-4.11-34.61-12.3-31.45-22.66-61.14-75.65-88.24-157.48-20.1-60.71-28.2-109.31-28.31-109.86l-1.7-12.47c.11.54,14.24,59.88,34.23,120.21,18.35,55.42,48.22,127.23,86.28,154.65,16.14,11.64,32.72,14.48,49.26,8.45,68-24.75,55.32-246.75,55.18-249l4.53-.43c0,.57,3.39,57.52-2,116.88-3.15,35-8.67,64-16.39,86.22-9.84,28.27-23.32,45.7-40.09,51.8A52.89,52.89,0,0,1,1105.17,1287.21Z" />
                              <path class="cls-25" d="M913.24,881.48a4,4,0,1,1,4,4A4,4,0,0,1,913.24,881.48Z" />
                              <path class="cls-25" d="M936.78,946.18l-1.63-2.13c.06-.06,5-5,2-18.61-3-14.09-20.75-43.07-20.93-43.36l2-1.2c.74,1.2,18.13,29.61,21.22,44.08C942.6,939.84,937.07,945.88,936.78,946.18Z" />
                              <path class="cls-13" d="M961.21,1076.83c-29.08-9.21-64.81-59.36-64.81-59.36l60.27,10.74C958,1041.82,959.64,1059,961.21,1076.83Z" />
                              <path class="cls-12" d="M1161.37,1001.35a7.72,7.72,0,0,1,1.3-3.89c.71-2.36,2.13-9.34,1.95-10.84-.58-4.75,2.93-3.78,4.29.2.29.87.21,3.16.4,4.11,4.7-3.17,12.15-6.56,24-9.4,0,0,9.09-2.58,4.41,6.28-3.13,5.91-6.59,6.53-11.14,14.8s-12.54,13.76-19.46,12.95l-7.55,2.21-7.07-11.44Z" />
                              <path class="cls-17" d="M894.22,964.19s-62.86,80.37,130.36,104.17c30.9,3.8,140.38-47.77,140.38-47.77s-3-14.27-12.5-16.39c0,0-103.34,10.91-111.24,16.58-7,5-83.63-51.24-106.55-58.52S894.22,964.19,894.22,964.19Z" />
                              <path class="cls-8" d="M1294.71,1623.17H1179a8.8,8.8,0,0,1-8.67-7.19c-2.95-15.64-7.82-52.82,7.69-71.72,12.24-14.91,50.74-11.54,78.71-6.7C1286.11,1569.42,1294.71,1623.17,1294.71,1623.17Z" />
                            </g>
                          </g>
                        </svg>
                      </div>
  
                    </td>
                  </tr>
  
                  <tr style="margin: 0px;">
                    <td style="padding: 12px 36px; text-align: center; color:#555555">
  
                      ${isSuccess?'<span style="font-size: 24px; line-height: 36px; color:#012729; letter-spacing:1px; font-weight:300"> Thank you! <br /> Your Appointment is Confirmed!</span>':
                      '<span style="font-size: 24px; line-height: 36px; color:#012729; letter-spacing:1px; font-weight:300"> Error occured! <br /> Please contact the clinic</span>'}
                     <br />
       
                     <br />
                     <table width="100%" border="0" cellpadding="0">
                       <tbody>
                         <tr style="background-color: #e0e0e0;">
                           <td style="font-size: 1px">&nbsp;</td>
                         </tr>
                       </tbody>
                     </table>
                      <br />
                      
  
                      <!-- SECTION 9 -->
  
                      <table>
                        <tr>
                          <td style="text-align: center; font-size: 16px; line-height: 24px; padding: 24px 24px 24px 24px;">
                            If you have any questions, contact us at <a href="tel:${branchData.phone}" style="color:#008489; text-decoration: none">${branchData.phone}</a> or email us at <a style="color:#008489; text-decoration: none" href="mailto:${branchData.email}">${branchData.email}</a></span>.
                            
                          </td>
                        </tr>
                      </table>
                      <!-- SECTION 9 -->
                      <table width="100%" border="0" cellpadding="0">
                        <tbody>
                          <tr style="background-color: #e0e0e0;">
                            <td style="font-size: 1px">&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                      <table width="100%" border="0" cellpadding="0">
                        <tr>
                          <td style="font-size: 14px; line-height: 24px; padding: 24px 24px 24px 24px; color:#909090; text-align:center;">
                            Powered by <a href="https://whskr.com" style="color:#008489; text-decoration: none">Whskr™</a>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
  
          </td>
        </tr>
      </tbody>
    </table>
    &nbsp;
  </body>
  </html>
`);

}
export default class AppointmentConfirmationHtml extends React.Component  {
    
   state = {isApproved:null,branchData:{}}

    componentDidMount(){
        let token = this.props.extra.token;
        let status = AppointmentServices.confirmAppointmentInEmail(token,data=>this.setState({isApproved:data.resultStatus,branchData:data.responseData}));
    }

   

    
    render(){
        let myhtml = getHtml(this.state.isApproved,this.state.branchData);
        return (
          this.state.isApproved === null ? null :
            <div  dangerouslySetInnerHTML={{ __html: myhtml}}/>);
    }
}