import axios from "axios";
import React  from "react";
import { message, Typography} from 'antd';
import { trackPromise} from 'react-promise-tracker';
import moment from "moment";
import CommonUtil from "../components/util/CommonUtil";
import BillingUtil from "../components/estimate/BillingUtil";

const { Text } = Typography;
export default class EstimateServices {
    

    static createEstimate = (inputData , dataUpdaterFunc=()=>{}) => {
        let resultStatus = true;
        trackPromise(
         axios.post("/api-route/api/v1/service/estimate",inputData)
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            if(resultStatus){
                message.success("Estimate "+CommonUtil.status_messages.create_success)
            } else {
                message.error(CommonUtil.status_messages.error)
            }
         
            dataUpdaterFunc(resultStatus)
        }
    
        )
        );

    }

    static updateEstimate = (inputData , dataUpdaterFunc=()=>{}) => {
        let resultStatus = true;
        trackPromise(
         axios.put("/api-route/api/v1/service/estimate",inputData)
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            if(resultStatus){
                message.success("Estimate "+CommonUtil.status_messages.update_success)
            } else {
                message.error(CommonUtil.status_messages.error)
            }
         
            dataUpdaterFunc(resultStatus)
        }
    
        )
        );

    }

    static searchEstimateByClientId = (id,dataUpdaterFunc=()=>{}) => {
        let resultantData = [];
        trackPromise(
         axios.get("/api-route/api/v1/service/estimate/client/"+id)
        .then(response => resultantData=response.data)
        .catch(err => {})
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }

    static searchEstimateByPatientId = (id,dataUpdaterFunc=()=>{}) => {
        let resultantData = [{id:1,name:"sdfsdf",statusId:1,total:3434.34}];
        trackPromise(
         axios.get("/api-route/api/v1/service/estimate/patient/"+id)
        .then(response => resultantData=response.data)
        .catch(err => {})
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }

    

    static getEstimateById = (id,dataUpdaterFunc=()=>{}) => {
        let resultantData = {};
        // let resultantData = {
        //     "name": "Test2",
        //     "subTotal": 950.0,
        //     "total": 900.0,
        //     "tax": 6.99,
        //     "priceVariance": 6.2,
        //     "discount": 6.1,
        //     "statusId": 1,
        //     "patient": {
        //         "id": 5626
        //     },
        //     "client": {
        //         "id": 13759
        //     },
        //     "items": [
        //         {
        //             "name": "Item3",
        //             "code": "T003",
        //             "price": 200.25,
        //             "decline":true,
        //             "qty": 3
        //         },
        //         {
        //             "name": "Item4",
        //             "code": "T004",
        //             "price": 500.25,
        //             "decline":false,
        //             "qty": 3
        //         }
        //     ]
        // };
        trackPromise(
         axios.get("/api-route/api/v1/service/estimate/"+id)
        .then(response => {
            let finalResponse = response.data;
            BillingUtil.frameInvoiceResponse(finalResponse);
            resultantData=finalResponse;
        })
        .catch(err => {})
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }

    static sendEstimateEmail = (id,data,dataUpdaterFunc=()=>{}) => {
        // let resultantData = "{
        //     "to" : [
        //         4,
        //         5
        //      ],
        //     "templateId": 4,
        //     "subject": "Test Subject",
        //     "message": "Test Message"
        //    }";
        let resultStatus = true;
        trackPromise(
         axios.post("/api-route/api/v1/service/estimate/"+id+"/send",data)
         .then(response => response.data)
         .catch(err => {resultStatus=false;})
         .finally( () => {
            if(resultStatus){
                message.success("Estimate "+CommonUtil.status_messages.email_success)
            } else {
                message.error(CommonUtil.status_messages.error)
            }
         
            dataUpdaterFunc(resultStatus)
        }
    
        )
        );

    }

    static approveEstimates = (token, dataUpdaterFunc=()=>{}) => {
        let resultStatus = false;
        let responseData={};
        trackPromise(
         axios.post("/api-route/estimate-approve?token="+token)
        .then(response => 
            { resultStatus= true ;responseData=response.data} )
        .catch(err => {
            
            resultStatus=false;})
        .finally( () => {
            //console.log("===>",responsedata)
            dataUpdaterFunc({responseData,resultStatus})
        }

        )
        );

    }


}