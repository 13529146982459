import axios from "axios";
import React  from "react";
import { message, Typography} from 'antd';
import { trackPromise} from 'react-promise-tracker';
import moment from "moment";
import { momentLocal, utcToLocal } from "../components/util/TimeUtil";
import CommonUtil from "../components/util/CommonUtil";

const { Text } = Typography;
export default class HistoryServices {


    

    static getAppointmentHistoryByClientId = (url="",id,dataUpdaterFunc=()=>{}) => {

 

        let resultantData = [];
        trackPromise(
         axios.get("/api-route/api/v1/service/appt-history/client/"+id+url)
        .then(response => resultantData=response.data)
        .catch(err => {})
        .finally( () => {
            dataUpdaterFunc(resultantData.map(k=>({...k,date:utcToLocal(k.date,"YYYY-MM-DD")})).sort((a,b)=>b.date.diff(a.date)))
        }

        )
        );

    }

    static getAppointmentHistoryByPatientId = (url="",id,dataUpdaterFunc=()=>{}) => {


       

        let resultantData = [];
        trackPromise(
         axios.get("/api-route/api/v1/service/appt-history/patient/"+id+url)
        .then(response => resultantData=response.data)
        .catch(err => {})
        .finally( () => {
            dataUpdaterFunc(resultantData.map(k=>({...k,date:momentLocal(k.date,"YYYY-MM-DD")})).sort((a,b)=>b.date.diff(a.date)))
        }

        )
        );

    }

    static getBillingHistoryByClientId = (url="",id,dataUpdaterFunc=()=>{}) => {

     


        let resultantData = [];
        trackPromise(
         axios.get("/api-route/api/v1/service/billing/client/"+id+url)
        .then(response => resultantData=response.data)
        .catch(err => {})
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }

    static getBillingHistoryByPatientId = (url="",id,dataUpdaterFunc=()=>{}) => {




        let resultantData = [];
        trackPromise(
         axios.get("/api-route/api/v1/service/billing/patient/"+id+url)
        .then(response => resultantData=response.data)
        .catch(err => {})
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }

    static getCommunicationsHistoryByClientId = (url="",id,dataUpdaterFunc=()=>{}) => {



        let resultantData = [];
        trackPromise(
         axios.get("/api-route/api/v1/service/comm/client/"+id+url)
        .then(response => resultantData=response.data)
        .catch(err => {})
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }

    static getCommunicationsHistoryByPatientId = (url="",id,dataUpdaterFunc=()=>{}) => {


       

        let resultantData = [];
        trackPromise(
         axios.get("/api-route/api/v1/service/comm/patient/"+id+url)
        .then(response => resultantData=response.data)
        .catch(err => {})
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }

    static getCommunicationsHistoryByCommId = (id,dataUpdaterFunc=()=>{}) => {


       

        let resultantData = [];
        trackPromise(
         axios.get("/api-route/api/v1/service/comm/"+id)
        .then(response => resultantData=response.data)
        .catch(err => {})
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }

    static saveCommunicationsHistory = (inputData ,dataUpdaterFunc=()=>{}) => {


        let resultStatus = true;
        let errormessage = "";
        trackPromise(
         axios.post("/api-route/api/v1/service/comm",inputData)
        .then(response => response.data)
        .catch(err => {if(err.status === 400)errormessage = err.data;resultStatus=false;})
        .finally( () => {
            if(resultStatus){
                message.success("Communication "+CommonUtil.status_messages.create_success)
            } else {
                message.error(errormessage ?? CommonUtil.status_messages.error)
            }
         
            dataUpdaterFunc(resultStatus)
        }
    
        )
        );

    }

    static editCommunicationsHistory = (inputData ,dataUpdaterFunc=()=>{}) => {


        let resultStatus = true;
        let errormessage = "";
        trackPromise(
         axios.put("/api-route/api/v1/service/comm",inputData)
        .then(response => response.data)
        .catch(err => {if(err.status === 400)errormessage = err.data;resultStatus=false;})
        .finally( () => {
            if(resultStatus){
                message.success("Communication "+CommonUtil.status_messages.update_success)
            } else {
                message.error(errormessage ?? CommonUtil.status_messages.error)
            }
         
            dataUpdaterFunc(resultStatus)
        }
    
        )
        );

    }

    static deleteCommunicationsHistory = (id,dataUpdaterFunc=()=>{}) => {
        let resultStatus = true;
        let errormessage = "";
        trackPromise(
         axios.delete("/api-route/api/v1/service/comm/"+id)
        .then(response => response.data)
        .catch(err => {if(err.status === 400)errormessage = err.data;resultStatus=false;})
        .finally( () => {
            if(resultStatus){
                message.success("Communication "+CommonUtil.status_messages.delete_success)
            } else {
                message.error(errormessage ?? CommonUtil.status_messages.error)
            }
         
            dataUpdaterFunc(resultStatus)
        }
    
        )
        );

      
    
    }

    static getPatientMedicalHistoryByPatientId = (url="",id,dataUpdaterFunc=()=>{}) => {




        let resultantData = [];
        let resultStatus = true;
        let errormessage = "";
        trackPromise(
         axios.get("/api-route/api/v1/service/report/medical-record/patient/"+id+url ,  {responseType: 'arraybuffer'})
         .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]
              ,{type: "application/pdf"}))
            var link = document.createElement('a');         
            link.href = url;
            link.setAttribute('download', 'PatientMedicalHistory.pdf');
            document.body.appendChild(link);
            link.click();
          })
        .catch(err => {if(err.status === 400)errormessage = err.data;resultStatus=false;})
        .finally( () => {
            if(resultStatus){
                message.success("Medical record downloaded Successfully. ")
            } else {
                message.error(CommonUtil.status_messages.error)
            }
         
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }


   





}