import {CommonContext} from './CommonContext';
import React,{ Component } from 'react';

export default class CommonProvider extends Component {
    state = {
        userProfile: {userName:""},
        branchDetails:[],
        defaultBranch:{},
        createEditAny:{type:"",callback:()=>{},inputData:null,optionalData:null},
        allStates:{},
        clientPatientDetails : [],
        isTabletMode : false,
        isApptFormTouched: false
    };

    render() {
        return (
            <CommonContext.Provider
                value={{
                    userProfile: this.state.userProfile,
                    branchDetails:this.state.branchDetails,
                    defaultBranch:this.state.defaultBranch,
                    createEditAny:this.state.createEditAny,
                    allStates:this.state.allStates,
                    clientPatientDetails:this.state.clientPatientDetails,
                    isTabletMode : this.state.isTabletMode,
                    isApptFormTouched:this.state.isApptFormTouched,
                    openCreateEditAny : updateFields => {
                        let createEditAny = {...this.state.createEditAny,...updateFields}
                        this.setState({
                            createEditAny
                        });
                    },
                    closeCreateEditAny : ()=>{
                        this.setState({createEditAny:{type:"",callback:()=>{},inputData:null}})
                    },
                    updateUserProfile: updateFields => {
                        const userProfile = Object.assign({}, this.state.userProfile);
                        Object.keys(updateFields).forEach(key => {
                            userProfile[key] = updateFields[key]
                        });
                        this.setState({
                            userProfile
                        });
                    },
                    updateStateFields: updateFields => {
                        let updatedStateFields = {};
                        Object.keys(updateFields).forEach(key => {
                            updatedStateFields[key] = updateFields[key]
                        });
                        this.setState({...updatedStateFields});
                    },
                    updatePageSpinner : isOn => {
                        this.setState({showPageSpinner:isOn });

                    }

                }}
            >
                {this.props.children}
            </CommonContext.Provider>
        );
    }
}