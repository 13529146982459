import axios from "axios";
import React  from "react";
import { message, Typography} from 'antd';
import { trackPromise} from 'react-promise-tracker';
import moment from "moment";
import CommonUtil from "../components/util/CommonUtil";

const { Text } = Typography;
export default class InventoryReminders {
    

    static createInventoryReminder = (inputData , dataUpdaterFunc=()=>{}) => {
           /*let resultantData = [
        {
            //add inventory id
            "type": 1,
            "name": "Test Description",
            "due": 4,
            "unit": "D",
            "startAlert": 8,
            "stopAlert": 10,
            "groups": [
                1,
                2
            ]
        }
    ]*/
        let resultStatus = true;
        trackPromise(
         axios.post("/api-route/api/v1/service/inventory-reminder",inputData)
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            if(resultStatus){
                message.success("Reminder "+CommonUtil.status_messages.create_success)
            } else {
                message.error(CommonUtil.status_messages.error)
            }
         
            dataUpdaterFunc(resultStatus)
        }
    
        )
        );

    }

    static updateInventoryReminder = (inputData , dataUpdaterFunc=()=>{}) => {
           /*let resultantData = [
        {
            //add inventory id
            "type": 1,
            "name": "Test Description",
            "due": 4,
            "unit": "D",
            "startAlert": 8,
            "stopAlert": 10,
            "groups": [
                1,
                2
            ]
        }
    ]*/
        let resultStatus = true;
        trackPromise(
         axios.put("/api-route/api/v1/service/inventory-reminder/",inputData)
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            if(resultStatus){
                message.success("Reminder "+CommonUtil.status_messages.update_success)
            } else {
                message.error(CommonUtil.status_messages.error)
            }
         
            dataUpdaterFunc(resultStatus)
        }
    
        )
        );

    }



    static deleteInventoryReminder = (id , dataUpdaterFunc=()=>{}) => {
        let resultStatus = true;
        trackPromise(
         axios.delete("/api-route/api/v1/service/inventory-reminder/"+id)
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            if(resultStatus){
                message.success("Reminder "+CommonUtil.status_messages.delete_success)
            } else {
                message.error(CommonUtil.status_messages.error)
            }
         
            dataUpdaterFunc(resultStatus)
        }
    
        )
        );

    }


    static getInventoryReminderById = (id,dataUpdaterFunc=()=>{}) => {
           /*let resultantData = [
        {
            "type": 1,
            "name": "Test Description",
            "due": 4,
            "unit": "D",
            "startAlert": 8,
            "stopAlert": 10,
            "groups": [
                1,
                2
            ]
        }
    ]*/
        let resultantData = [];
        trackPromise(
         axios.get("/api-route/api/v1/service/inventory-reminder/"+id)
        .then(response => resultantData=response.data)
        .catch(err => console.log("Error occurred at getInventoryReminderById API: " + err))
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }



    static getInventoryRemindersByInventoryId = (id,dataUpdaterFunc=()=>{}) => {
        /*let resultantData = [
        {
            "type": 1,
            "name": "Test Description",
            "due": 4,
            "unit": "D",
            "startAlert": 8,
            "stopAlert": 10,
            "groups": [
                1,
                2
            ]
        }
    ]*/
        let resultantData = [];
        trackPromise(
         axios.get("/api-route/api/v1/service/inventory-reminder/inventory/"+id)
        .then(response => resultantData=response.data)
        .catch(err => console.log("Error occurred at getInventoryRemindersByInventoryId API: " + err))
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }

   


}