import axios from "axios";
import React  from "react";
import { Typography} from 'antd';
import { trackPromise} from 'react-promise-tracker';
import moment from "moment";

const { Text } = Typography;

export default class LotServices {

    static createLotByInventoryID = (inputData , dataUpdaterFunc=()=>{}) => {
        /*
            {
    "inventoryId": 16,
    "qty": 4,
    "lotNumber": "Xyz-ABC-1234",
    "manufacturer": "Zoetis",
    "expiryDate": "2021-08-08",
    "cost": 200.5,
    "price": 200.5,
    "poNumber": "123-abc",
    "poVendor": "Zoetis1"
}
        */
        let resultStatus = true;
        trackPromise(
         axios.post("/api-route/api/v1/service/inventory-lot",inputData)
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            dataUpdaterFunc(resultStatus)
        }

        )
        );

    }

    static updateLotByInventoryID = (inputData , dataUpdaterFunc=()=>{}) => {

        /*
        {
    "lotId": 1,
    "inventoryId": 16,
    "qty": 4,
    "lotNumber": "Xyz-ABC-1234",
    "manufacturer": "Zoetis",
    "expiryDate": "2021-09-08",
    "cost": 200.5,
    "price": 200.5,
    "poNumber": "123-abc",
    "poVendor": "Zoetis2"
}
        */
        let resultStatus = true;
        trackPromise(
         axios.put("/api-route/api/v1/service/inventory-lot",inputData)
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            dataUpdaterFunc(resultStatus)
        }

        )
        );

    }



    static getLotById = (id,dataUpdaterFunc=()=>{}) => {
        /*

            {
    "lotId": 1,
    "inventoryId": 16,
    "qty": 4.0,
    "lotNumber": "Xyz-ABC-1234",
    "manufacturer": "Zoetis",
    "expiryDate": "2021-09-08",
    "cost": 200.5,
    "price": 200.5,
    "poNumber": "123-abc",
    "poVendor": "Zoetis2"
}
        */
        let resultantData = [];
        trackPromise(
         axios.get("/api-route/api/v1/service/inventory-lot/"+id)
        .then(response => resultantData=response.data)
        .catch(err => console.log("Error occurred at getLotById API: " + err))
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }


    static getLotByInventoryId = (id,dataUpdaterFunc=()=>{}) => {
        let resultantData = [];
        /*

        [
    {
        "lotId": 1,
        "inventoryId": 16,
        "qty": 4.0,
        "lotNumber": "Xyz-ABC-1234",
        "manufacturer": "Zoetis",
        "expiryDate": "2021-09-08",
        "cost": 200.5,
        "price": 200.5,
        "poNumber": "123-abc",
        "poVendor": "Zoetis2"
    }
]

        */

        trackPromise(
         axios.get("/api-route/api/v1/service/inventory-lot/inventory/"+id)
        .then(response => resultantData=response.data)
        .catch(err => console.log("Error occurred at getLotByInventoryId API: " + err))
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }

    static deleteLotById = (id,dataUpdaterFunc=()=>{}) => {
        /*

            {
    "lotId": 1,
    "inventoryId": 16,
    "qty": 4.0,
    "lotNumber": "Xyz-ABC-1234",
    "manufacturer": "Zoetis",
    "expiryDate": "2021-09-08",
    "cost": 200.5,
    "price": 200.5,
    "poNumber": "123-abc",
    "poVendor": "Zoetis2"
}
        */
       let resultStatus = true;
        trackPromise(
         axios.delete("/api-route/api/v1/service/inventory-lot/"+id)
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            dataUpdaterFunc(resultStatus)
        }

        )
        );

    }

    static updateLotStatusByLotId = (lotId,isActive , dataUpdaterFunc=()=>{}) => {

        let resultStatus = true;
        trackPromise(
         axios.put(`/api-route/api/v1/service/inventory-lot/${lotId}/status/${isActive?"y":"n"}`)
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            dataUpdaterFunc(resultStatus)
        }

        )
        );

    }



}