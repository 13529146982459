import moment from "moment";

const utcToLocal=(input,format)=>{
return (input && format)?moment.utc(input,format).local():null
}

const localToUtc=(input)=>{
    return input && input?input.utc():null

}

const momentLocal = moment;

export {utcToLocal,localToUtc,momentLocal}