import { Button, Card, Checkbox, Col, Row, Select, Space, Typography } from 'antd';
import React, { useContext } from "react";
import { CommonContext } from '../../context/CommonContext';
import CommonUtil from "../util/CommonUtil";
import "./AppointmentModal.scss";
const apptlength = [{name:"15 MIN",value:15},{name:"30 MIN",value:30},{name:"45 MIN",value:45},{name:"1 HR",value:60},{name:"1.5 HR",value:90}, {name:"2 HR",value:120}]
const { Text } = Typography;
const { Option } = Select;
const CreateApptStep1 = ({appointmentData, setAppointmentData,patientDetails,updatePrimaryDoctor}) => {
    const context = useContext(CommonContext);
    const appttype = Object.values(context.defaultBranch.branchTypeId === 2 ? CommonUtil.APPOINTMEN_TYPES_BRANCH_TYPE_2 : CommonUtil.APPOINTMEN_TYPES); 
   
    return (
        <div className="site-card-border-less-wrapper">
            <Card bordered={false} className="appt-card">
                <Row><Col span={24}>
                    <Row>
                        <Col span={24}>
                        <Space size={8} direction="vertical" style={{ width: "100%" }}>
                        <Text>Patient Name</Text>
                        <Select 
                        size="large"
                        showSearch = {true}
                        value={appointmentData.patientId}
                        // filterOption={(input, option) =>
                        //     option.children??"".toLowerCase().indexOf(input.children?.toLowerCase()) >= 0
                        // }
                        filterOption={(input, option) =>
                            option.extra.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        // filterSort={(optionA, optionB) =>
                        //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        //  }
                        onChange={(value)=> {setAppointmentData(k=>({...k,patientId:value})); updatePrimaryDoctor(value)} }
                        placeholder={<Text>{context.defaultBranch.branchTypeId !== 2 ? "Select a Patient Name" : "Select pet name"}</Text>}
                        style={{ width: "100%", marginBottom:'24px' }}>
                        {patientDetails.map(k=> <Option key={k.id} value={k.id} extra={k.name}>
                            <Row><Col style={{fontWeight:500}}>{k.name}</Col></Row>
                            <Row><Col className="font-size-12">{k.breed}</Col></Row>
                        
                        </Option>)}
                       
                           
                        </Select>
                        </Space>
                        </Col>
                    </Row>
                    <Space size={8} direction="vertical" style={{ width: "100%" }}>
                        <Text>Appointment Type</Text>

                        <Row justify="space-evenly" style={{marginBottom:'24px' }} gutter={[8, 8]} >
                            {
                                appttype.map(type => <Col span={8}>
                                    <Button
                                        type={appointmentData.appointmentType === type.value ? "primary" : "default"}
                                        shape="round"
                                        size="small"
                                        value={type.value}
                                        onClick={(event) => {
                                            let updateObject = { appointmentType: type.value }
                                            if (type.presetTime) {
                                                updateObject["appointmentLength"] = type.presetTime;
                                            }
                                            if (type.isDropOff) {
                                                updateObject["isDropOffAppointment"] = true;

                                            }
                                            setAppointmentData(k => ({ ...k, ...updateObject }))
                                        }}
                                        className={appointmentData.appointmentType === type.value ? "primary-button-color appt-button" : "appt-button"}>
                                        {type.name}</Button>
                                </Col>)



                            }
                        </Row>
                        </Space>
                    <Space direction="vertical" size={8} style={{ width: "100%" }}>

                        <Text>Appointment Length</Text>

                        <Row justify="start" gutter={[8, 8]} style={{marginBottom:'24px' }}>
                            {
                                apptlength.map(apptlen => <Col span={8}>
                                    <Button
                                        type={appointmentData.appointmentLength === apptlen.value ? "primary" : "default"}
                                        shape="round"
                                        size="small"
                                        value={apptlen.value}
                                        onClick={(event) => {
                                            setAppointmentData(k => ({ ...k, "appointmentLength": apptlen.value }))
                                        }
                                        }
                                        className={appointmentData.appointmentLength === apptlen.value ? "appt-button-color appt-time-button" : "appt-time-button"}>
                                        {apptlen.name}</Button> </Col>)



                            }
                        </Row>
                    </Space>

                    <Checkbox className="hider" onChange={(value) => { setAppointmentData(k => ({ ...k, isDropOffAppointment: !appointmentData.isDropOffAppointment })) }} checked={appointmentData.isDropOffAppointment}>Drop Off Appointment</Checkbox>
                  </Col>              
                </Row>
            </Card>
        </div>

    )


}

export default CreateApptStep1;