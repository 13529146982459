import React, { useState,useEffect, useContext } from 'react';
import { Button, Drawer,Form } from 'antd';
import PatientCreateEditForm from "./patient-create-edit-form/PatientCreateEditForm";

import PatientServices from './../../services/PatientServices';

import ProfileImageUpload from '../generic-components/profile-image/ProfileImageUpload';
import CommonUtil from '../util/CommonUtil';
import { CommonContext } from '../../context/CommonContext';
import PriceUtil from '../util/PriceUtil';


const CreateEditPatientDrawer = (props) => {
    const [requiredInputData,setRequiredInputData] = useState({familyList:[],speciesList:[],breedList:[],colorList:[],statusList:[],doctorsList:[],clientLastName:""});
    const [form] = Form.useForm();
    const [uploadPhoto,setUploadPhoto] = useState(null);
    const commonContext = useContext(CommonContext);


    useEffect(()=>{
        form.setFieldsValue({weightUnitCd:"LBS",insurance:false,weightEstimated:false,clientLastName:props.clientLastName});
        
        if(props.patientData){
        form.setFieldsValue(props.patientData);
        PatientServices.fetchBreedByFamily(props.patientData.familyId,(data)=>setRequiredInputData(k=>({...k,breedList:data})));
        PatientServices.fetchColorByBreed(props.patientData.colorId,(data)=>setRequiredInputData(k=>({...k,colorList:data})));
        PatientServices.fetchSpeciesByFamily(props.patientData.familyId,(data)=>setRequiredInputData(k=>({...k,speciesList:data})));
        //setRequiredInputData(k=>({...k,clientLastName:props.patientData.clientLastName}));
        setRequiredInputData(k=>({...k,insurance:props.patientData?.insurance}))
        }
        PatientServices.fetchAnimalFamily((data)=>setRequiredInputData(k=>({...k,familyList:data})));

    },[props.patientData]);

    useEffect(()=>{
       
        PatientServices.fetchPatientStatus((data)=>setRequiredInputData(k=>({...k,statusList:data})));
        PatientServices.fetchPrimaryDoctosForPatient((data)=>setRequiredInputData(k=>({...k,doctorsList:data})));

    },[]);



    const onClose = () => props.onClose();
    const handleSubmit = (submitData) => {
        
        if(submitData.freeForm){
            submitData.freeForm = Object.keys(submitData.freeForm).filter(k => submitData.freeForm[k]).map(k=>({code: CommonUtil.FREEFORM_STRING_ID_CONV[k].value,value:submitData.freeForm[k]}))
        }
        if(submitData.dob){
            submitData.dob = submitData.dob.format("YYYY-MM-DD")
        }
        
        if(submitData.weight){
            submitData.weight = PriceUtil.convertFloat(submitData.weight);
        }
        
        //console.log(JSON.stringify(submitData));
        if ( props?.patientData?.patientId){
            let callPatientEdit =  ()=>PatientServices.updatePatient({...submitData , patientId : props.patientData.patientId , clientId :  props.patientData.clientId}, props.onSuccessReturn)

            if(uploadPhoto ){
                PatientServices.uploadPatientPic(props.patientData.patientId,uploadPhoto,()=>{callPatientEdit()})
            }else {
                callPatientEdit();
            }
            //ClientServices.updateClient({...submitData , clientId : props.clientData.clientId} , props.onSuccessReturn);
        } else {
            PatientServices.createPatient({...submitData,clientId :  props.clientId},uploadPhoto,props.onSuccessReturn,commonContext.updateStateFields);
        }
        
        onClose();
       
    }

    const onFormValueChange = (formValue) => {
        let key = Object.keys(formValue)[0];
        switch(key){
            case "familyId" : 
             form.setFieldsValue({"breedId":null,"speciesId":null});
             PatientServices.fetchBreedByFamily(formValue[key],(data)=>{
                 setRequiredInputData(k=>({...k,breedList:data}))
                if(formValue[key] === 1){
                    form.setFieldsValue({"speciesId":22});
                }
                if(formValue[key] === 3){
                    form.setFieldsValue({"speciesId":25});
                }
                });
             PatientServices.fetchSpeciesByFamily(formValue[key],(data)=>setRequiredInputData(k=>({...k,speciesList:data})));
                break;
             case "breedId" : 
             form.setFieldsValue({"colorId":null});
             PatientServices.fetchColorByBreed(formValue[key],(data)=>setRequiredInputData(k=>({...k,colorList:data})));
                break;
             case "insurance" :
                setRequiredInputData(k=>({...k,insurance:formValue[key]}))
                break;
             
            }
    }

    return (
        <Drawer className='client-edit-profile'
            title={props.isEdit ? "Edit Patient" : "Add Patient"}
            width={473}
            onClose={onClose}
            visible={true}
            bodyStyle={{ paddingBottom: 50 }}
            footer={
                <div className='footer-button'>
                    {/* <Button
                        className="drawerAltButton"
                        size="large"
                        type="link"
                        style={{ marginRight: 8 }}>
                        Merge Profile
                        </Button> */}
                    <Button 
                        shape="round"
                        size="large"
                        onClick={onClose}
                        style={{ marginRight: 16 }}>
                        Close
                        </Button>
                    <Button
                        htmlType="submit"
                        form="patient-form-id"
                        type="primary"
                        size="large"
                        shape="round">
                        {props.isEdit?"Update":"Save"}
                        </Button>
                </div>
            }>
           <ProfileImageUpload 
           onUpload={(file)=>setUploadPhoto(file)}
        //    onUpload={(file,onSuccess) => PatientServices.uploadPatientPic(props.patientData.patientId,file,()=>{onSuccess("done");message.success("Uploaded Succesfully");props.onSuccessReturn()})} 
        imageSrc={props.patientData?.image}/>
            <PatientCreateEditForm 
             patientform = {form}
             handleSubmit={handleSubmit} 
             onFormValueChange = {onFormValueChange}
             requiredInputData={requiredInputData}
             isEdit = {props.isEdit}
             patientDelete={  () => {PatientServices.deletePatient(props.patientData.patientId,  props.patientDelete,commonContext.updateStateFields);}}/>
        </Drawer>
    );

}
export default CreateEditPatientDrawer;