import React from 'react';
import ReactDOM from 'react-dom';
import './index.less';
import './customstyle.scss'
import App from './App';
import * as serviceWorker from './serviceWorker';
import CommonProvider from './context/CommonProvider';
import ErrorBoundary from './ErrorBoundary';
//
// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

ReactDOM.render(
      <CommonProvider>
      <ErrorBoundary>
      <App />
      </ErrorBoundary>
      </CommonProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
