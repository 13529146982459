import React from "react";
import EstimateServices from "../../services/EstimateServices";


const getHtml=(isSuccess,branchData)=>{

  return(`
  <!DOCTYPE html>
<html>

<head>
<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&family=WindSong:wght@400;500&display=swap" rel="stylesheet">
</head>

<body style="background-color:#f1f5fa">
<table style="border-spacing: 0px; background-color:#f1f5fa; font-family: 'Poppins', sans-serif; padding-top:12px; padding-bottom:12px; border-collapse: collapse" border="0" width="100%">
<tbody>

<tr>
  <td style="width: 100%; align-content: center; text-align: center; padding:24px;">
    <div align="center">
      <table style="max-width: 600px; text-align: -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.09); 
box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.09);center; border-spacing: 0px; background-color:#ffffff; border-radius: 16px;">
        <tbody>
          <tr>
            <td align="center" style="padding:36px 36px 12px 36px">
              <div style="width:250px">
                <!-- Generator: Adobe Illustrator 26.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1935.9 1256.2">
                  <defs>
                    <style>
                      .cls-1,
                      .cls-2 {
                        fill: #008489;
                      }

                      .cls-1 {
                        opacity: 0.22;
                      }

                      .cls-2 {
                        opacity: 0.08;
                      }

                      .cls-3 {
                        fill: #699ff0;
                      }

                      .cls-4 {
                        fill: #466aa5;
                      }

                      .cls-22,
                      .cls-5 {
                        fill: #f1a34f;
                      }

                      .cls-5 {
                        opacity: 0.56;
                      }

                      .cls-6 {
                        fill: #2c3a64;
                      }

                      .cls-7 {
                        fill: #35488b;
                      }

                      .cls-8 {
                        fill: #e9f2ff;
                      }

                      .cls-9 {
                        fill: #f4ac75;
                      }

                      .cls-10,
                      .cls-14,
                      .cls-15 {
                        fill: #b2cbfd;
                      }

                      .cls-10 {
                        opacity: 0.2;
                      }

                      .cls-11 {
                        fill: #c97c47;
                      }

                      .cls-12 {
                        fill: #b64c41;
                      }

                      .cls-13 {
                        fill: #d6716b;
                      }

                      .cls-15 {
                        opacity: 0.5;
                      }

                      .cls-16 {
                        fill: #3d848d;
                      }

                      .cls-17 {
                        fill: #223d75;
                      }

                      .cls-18 {
                        fill: #d3671e;
                      }

                      .cls-19 {
                        fill: #e57970;
                      }

                      .cls-20 {
                        fill: #2b478b;
                      }

                      .cls-21 {
                        fill: #1d3972;
                      }

                      .cls-23 {
                        fill: #deedff;
                      }

                      .cls-24 {
                        fill: #82b378;
                      }

                      .cls-25 {
                        fill: #a8d29f;
                      }

                      .cls-26 {
                        fill: #ffb973;
                      }

                      .cls-27 {
                        fill: #d86713;
                      }
                    </style>
                  </defs>
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                      <path class="cls-1" d="M321.86,1256S-218.06,853.67,100.69,447C346.63,133.29,753.77,364.87,848.55,330.4s298.88-431.09,702.79-306.14C2051.4,179,1850.58,875.25,1678.19,1255Z" />
                      <path class="cls-2" d="M421.08,1256S-31.32,918.9,235.76,578.19C441.82,315.31,783,509.34,862.37,480.46S1112.8,119.26,1451.23,224c419,129.61,250.73,713,106.29,1031.24Z" />
                      <polygon class="cls-3" points="1518.98 1256.02 636.81 1256.02 636.81 1010.92 1518.98 1008.47 1518.98 1256.02" />
                      <rect class="cls-3" x="636.81" y="853.23" width="882.17" height="50.76" />
                      <rect class="cls-4" x="606.94" y="816.64" width="940.79" height="36.59" rx="16.34" transform="translate(2154.66 1669.87) rotate(180)" />
                      <polygon class="cls-5" points="1511.13 1008.47 644.65 1010.41 644.65 903.99 1511.13 903.99 1511.13 1008.47" />
                      <path class="cls-6" d="M424.55,1180s-16.09,14.92-12.95,25.52,17.67,17.67,19.63,30.62,9.81,20,16.88,20H481.8s5.21-9.12-7.36-11.87-17.3-18-16.51-32.11-1.18-33-1.18-33l-26.7-6.29Z" />
                      <path class="cls-6" d="M625.19,1255.65a5.37,5.37,0,0,0,1,.37h90.12c-.78-3.19-3.67-9.26-14.22-11-14.07-2.28-38.46-10.46-43.9-28.46-.17-.58-.33-1.17-.47-1.77-4.32-19.24-35.73,5.89-35.73,5.89s-.09.77-.23,2.07C621.09,1229.83,619.22,1252.67,625.19,1255.65Z" />
                      <path class="cls-7" d="M698.4,741.08S693.63,800.6,688,872.14c-7.62,97.19-16.78,216.56-17.69,239.18-1.56,39.27-7.85,102.08-7.85,102.08s-14.92,14.93-44.76,8.64c0,0-26.6-89.67-34.2-184.55-.11-1.56-.23-3.12-.35-4.69-7.07-96.58-18.06-300-18.06-300Z" />
                      <path class="cls-6" d="M698.4,741.08S693.63,800.6,688,872.14l-104.5,165.35c-.11-1.56-.23-3.12-.35-4.69-7.07-96.58-18.06-300-18.06-300Z" />
                      <path class="cls-7" d="M676.62,748.15S590.63,963.7,534.1,1065s-74.21,129.57-74.21,129.57-32.39-13-35.34-21.79c0,0,21.62-195.56,59.49-243.23,21.39-26.94,46.52-107.78,47.7-136s7.66-84.22,7.66-84.22Z" />
                      <path class="cls-8" d="M906.07,542.43c-3.82-4.16-10.48-6.3-10.48-6.3s-135-5.75-139.29-11.51c-3.33-4.47-23.64-51.09-32.53-71.66q-4.24-9.84-9.09-19.38l-23.85-47.12-39.57-1.39c-9.61,13.8-18.91,29.83-19.62,41C630,452,721.52,604.29,755.27,602.16c31.43-2,159.3-47.22,159.3-47.22S910.07,546.78,906.07,542.43Z" />
                      <path class="cls-9" d="M643.48,367c-32,10.11-87.47-17.18-87.47-17.18s25-18.75,27.8-57.66c0-.54.07-1.07.1-1.62.16-2.88.2-5.88.1-9l8.77,7.06,32.82,26.46s-1.68,13.05-2.36,34.79a68,68,0,0,1,12.53,8.81A49.94,49.94,0,0,1,643.48,367Z" />
                      <path class="cls-9" d="M520.46,345.67s84.67-13.35,140.46,23.58c0,0,37.91,247.77,4.9,271.22s-145.36,0-145.36,0Z" />
                      <path class="cls-8" d="M633.46,907h-153s17.78-261.1,22.86-324.66c4.12-51.56,12.84-175.17,16-220,.73-10.46,1.17-16.65,1.17-16.65L557,339.45l62.74,81.32s9.93,216.87,9.56,251.05,4,167.36,4.17,189.75S633.46,907,633.46,907Z" />
                      <path class="cls-8" d="M619.73,420.77,633.45,907h72.1S702.21,587.21,702,557.83s7.83-156-14.66-175.6c-18.81-16.42-64.07-32.36-64.07-32.36Z" />
                      <path class="cls-8" d="M623.71,338.65s20.55,14.16,19.78,19.24a96.09,96.09,0,0,1-3.6,13.12l-12.57-2.55,10.61,7.47s-12,35.08-18.2,44.84Z" />
                      <path class="cls-10" d="M510.68,483.49c9-55.31,113,26.42,104.33,99C599,716.65,530.92,866.28,480.44,907Z" />
                      <path class="cls-11" d="M583.81,292.16a4,4,0,0,0-.47-1.49l.57-.13,8.87-1.92,32.82,26.46s-1.42,12.33-2.44,34.85C623.08,349.81,589.34,331.14,583.81,292.16Z" />
                      <path class="cls-9" d="M639,323.12s-57.14-1.6-55.6-37.17-4.79-59.73,31.76-60.21,43,13.4,45.42,25.39S653.88,322.62,639,323.12Z" />
                      <path class="cls-6" d="M583.34,290.67s7.44,2,13.34-5.89,15.71-38.11,28.68-40.08,30,7.66,35.56,2.16c8-8,18.4-21.13,18.4-21.13s-64.31-45.85-90-24.64S554.69,253.35,583.34,290.67Z" />
                      <path class="cls-6" d="M660.52,417.7c-1.21-22-13.23-57.92-13.33-58.27l3.42,1.53c.44,1.43,10,34.19,11.22,56.69Z" />
                      <path class="cls-6" d="M577.3,483.49c-1.21-1.26-30.63-29.55-24.35-49.37,2.44-7.72,6.94-10.76,11.72-13,4.46-1.62,14.85-3.31,20.22.06,9.8,6.17,14.74,23.21,16.19,51.55l-1.19.06C598.17,439.17,589.5,427,583,424.25c-8.14-3.45-22.42-2-26.91,11-3.09,9,3,21.51,8.61,30.12,6.26,9.57,13.08,18,13.15,18.12Z" />
                      <path class="cls-12" d="M580.35,486.54h0a2.9,2.9,0,0,1-4.1,0l-2-2a2.9,2.9,0,0,1,0-4.1h0a2.9,2.9,0,0,1,4.1,0l2,2A2.9,2.9,0,0,1,580.35,486.54Z" />
                      <path class="cls-12" d="M600.59,478.13h0a2.9,2.9,0,0,1-2.89-2.89v-2.85a2.9,2.9,0,0,1,2.89-2.9h0a2.9,2.9,0,0,1,2.9,2.9v2.85A2.9,2.9,0,0,1,600.59,478.13Z" />
                      <path class="cls-6" d="M653.07,417.7a7.86,7.86,0,1,1,7.85,7.86A7.84,7.84,0,0,1,653.07,417.7Z" />
                      <path class="cls-13" d="M657.23,417.7a4,4,0,1,1,4,4A4,4,0,0,1,657.23,417.7Z" />
                      <path class="cls-14" d="M625.31,907c0-.49-5-49.47-3.57-92,.6-18,.09-39.13-.4-59.58-.7-29.17-2.27-57.26.09-75.58,4-31.34,3.23-173.82,1.57-189.26-1.61-15.05-.07-31.57,1.42-36.55s-5-32.86-5.07-33.14l.77-.2c.29,1.17,7.33,28.53,5.74,33.81-1.47,4.93-3.68,21-2.08,36,1.66,15.45,4.83,157.9.79,189.28-2.35,18.26-3.14,46.48-2.44,75.62.49,20.46,1,41.62.4,59.63-1.43,42.52,5.79,91.52,5.84,92Z" />
                      <path class="cls-8" d="M589.8,536.42s3.08,6.28,2.73,11.92c-.36,5.89-3.12,14.8-3.12,14.8s-119.53,25.44-142.76,4.17C421.71,544.47,468,372.9,487.78,356.12c8.54-7.24,26.53-11.66,43.14-14.3,9.16-1.46,17.89-2.38,24.39-2.94,6.69-.58,11-.76,11-.76l-2.7,10-10.48,38.74L540.94,432s-27.17,76.55-28.35,83.62S589.8,536.42,589.8,536.42Z" />
                      <path class="cls-10" d="M606.44,408.75S576,395.48,554.05,383.39c-14.3-7.89-21.63-22.68-23.13-41.57,9.16-1.46,17.89-2.38,24.39-2.94,1.05.34,1.68.57,1.68.57l6.65,8.63Z" />
                      <path class="cls-8" d="M619.73,420.77l-47.47-90.53s-17.71,3.59-23.66,9.16c0,0,3,27.23,5.38,33.25,0,0,9.17-1,10.48-.26,0,0-4.45,6.81-5.76,7.6C558.7,380,600,399.22,619.73,420.77Z" />
                      <path class="cls-6" d="M564.8,424.25c-3.24-7.17-31.83-68.23-24.55-83.77l2.79-.34C537.53,351.91,557.35,405,566,424Z" />
                      <path class="cls-15" d="M627.81,477.11a3.81,3.81,0,1,1,3.81,3.81A3.82,3.82,0,0,1,627.81,477.11Z" />
                      <path class="cls-15" d="M627.81,690.63a3.81,3.81,0,1,1,3.81,3.81A3.81,3.81,0,0,1,627.81,690.63Z" />
                      <path class="cls-15" d="M629.73,593.05a3.82,3.82,0,1,1,3.82,3.81A3.81,3.81,0,0,1,629.73,593.05Z" />
                      <path class="cls-15" d="M629.73,783.4a3.82,3.82,0,1,1,3.82,3.81A3.81,3.81,0,0,1,629.73,783.4Z" />
                      <path class="cls-16" d="M587.57,359.43s13.21,6.51,34.56,2.48l-2.4,58.86Z" />
                      <path class="cls-9" d="M583.48,540.15s21.45-9.43,31.27-6.68,23.07,4.72,24.11,8.25-7.07,16.63-19.13,17.29-33.27,6.29-38.38,0S583.48,540.15,583.48,540.15Z" />
                      <path class="cls-10" d="M699.63,417.7c4.51,31.21,2.34,138,2.34,138l6.32,8.08,5.25,6.4,2.8,3.28C723.89,541.86,699.63,417.7,699.63,417.7Z" />
                      <path class="cls-9" d="M888.44,538.4s19.62-12.8,29.76-11.68,17.19,4,18.79,7.31,2.08,14.45-9.71,17.07-31.8,11.62-37.87,6.25S888.44,538.4,888.44,538.4Z" />
                      <path class="cls-17" d="M1042,683.9s25.13-14.25.71-32.24c0,0,11-4.77,21.92,6.21a11.32,11.32,0,0,0,.57-6.19s15.87,10.85,11.49,26.16c0,0,7.86-4.75,9.14-9.12,0,0,10.51,18.15-5.8,30.49a12.93,12.93,0,0,0,9.31-2.24s-3,11-13.46,12.21Z" />
                      <path class="cls-18" d="M1101.66,816.64H871.07a13.24,13.24,0,0,1-12.92-10.32l-21.57-95.24h299.56l-21.56,95.24A13.24,13.24,0,0,1,1101.66,816.64Z" />
                      <path class="cls-17" d="M977.28,575.9s.64-23.53-13.37-35.89a124.94,124.94,0,0,0-17.36-12.41c-6.32-3.85-7.46-1.55-6.74,5.84.56,5.75-1,13.18-.09,17,2.91,11.93,3.76,20.57,16.23,26.1S977.28,575.9,977.28,575.9Z" />
                      <path class="cls-19" d="M901.16,582.22s-12.29,1-12,4.23,33.66-1.61,33.35-3.68S905.53,581.84,901.16,582.22Z" />
                      <path class="cls-20" d="M1058.13,693.88s-2.73-28-33.35-41.26c-15.92-6.88-70.85-28.18-51.07-49.22,10.07-10.72,11.47-22.14,10.4-30.78-.15-1.21-.34-2.37-.57-3.46h0a39.38,39.38,0,0,0-3.75-10.62,8.75,8.75,0,0,0-.58-1.1,24,24,0,0,0,.16-13.63c-1.7-6.62-8-12-12.54-17.27-4.78-5.65-6.59-3.83-8.24,3.41-1.22,5.35-4.77,11.53-5.4,15.52a85.08,85.08,0,0,0-12.83.08h0c-1.56.11-3.18.27-4.86.47-16.52,2-18.12,9.71-25.62,9.61-6.84-.09-15.57-.48-17-.55h-.2s-9.88-2.4-9.11,6.45c.09.94.17,1.85.25,2.7.57,6.19,1.2,9.86,3.66,12.19,1.87,1.77,9,3.66,14.23,4.17,4.07.4,13.64.83,14.16,1,1.43.41-5.41,4-25.74,3.74h-1.11a1.88,1.88,0,0,0-1.89,1.71,7.63,7.63,0,0,0,3.05,6.92c4.64,3.75,22.78,3.56,20.18,8.59a9.17,9.17,0,0,1-2.93,3h0c-5,3.54-13.88,6.83-16.49,14,0,0,6.53-2.73,11.25-2,0,0-15.35,5.05-15.82,12.82a8.53,8.53,0,0,1,5.61-1.53s-12.5,12-11.38,29.76h0c-7.69,10-19.37,23.44-27.9,25.59,0,0-7.21-4.48-10.57-4.47s-8.07,5.31-7.1,9.2c1.6,6.41,17.61,4.84,20.1,4.83,3,0,35.29.13,58.79.13h144.3Z" />
                      <path class="cls-21" d="M892.8,555.09c-1.11,3-3.85,8.61-9.06,9.14-.08-.85-.16-1.76-.25-2.7-.77-8.85,9.11-6.45,9.11-6.45Z" />
                      <path class="cls-17" d="M876.45,684.16S896.59,669,922,665.76s63.69,5.37,63.69,5.37Z" />
                      <path class="cls-17" d="M1024.78,652.62c-57.81-1.71-108.95-47.86-80.69-48.69s39.45-34.77,39.45-34.77h0c.23,1.09.42,2.25.57,3.46,1.07,8.64-.33,20.06-10.4,30.78C953.93,624.44,1008.86,645.74,1024.78,652.62Z" />
                      <path class="cls-17" d="M955.93,547.77a21.19,21.19,0,0,1,2.3-6.7c2.1-4.32,2-11.61,2.95-11.45s9.2,18,3.49,23.16C964.67,552.78,959.73,554.37,955.93,547.77Z" />
                      <path class="cls-18" d="M880.51,658.59c-1.12-17.76,11.38-29.76,11.38-29.76a8.53,8.53,0,0,0-5.61,1.53c.47-7.77,15.82-12.82,15.82-12.82-4.72-.73-11.25,2-11.25,2,2.61-7.19,11.54-10.48,16.49-14h0c.3-.1,8.59-2.85,11.19,2.1S880.54,639.19,880.51,658.59Z" />
                      <rect class="cls-22" x="821.71" y="693.75" width="329.3" height="17.33" rx="4.48" />
                      <path class="cls-21" d="M931.39,557.76c-.89,1.55-2.59,2.24-3.8,1.55s-1.45-2.52-.55-4.07,2.59-2.24,3.8-1.55S932.29,556.21,931.39,557.76Z" />
                      <rect class="cls-3" x="1222.06" y="165.01" width="713.85" height="251.76" />
                      <rect class="cls-23" x="1589.66" y="185.73" width="326.23" height="210.32" />
                      <rect class="cls-23" x="1240.7" y="185.73" width="326.23" height="210.32" />
                      <path class="cls-4" d="M1556.41,244.28a3.19,3.19,0,0,0-3.19,3.19v21.22c-5.28,2.62-9.16,11.56-9.16,22.2s3.88,19.58,9.16,22.19v21.23a3.19,3.19,0,1,0,6.37,0V247.47A3.18,3.18,0,0,0,1556.41,244.28Z" />
                      <path class="cls-4" d="M1600.62,244.28a3.19,3.19,0,0,1,3.19,3.19v21.22c5.27,2.62,9.16,11.56,9.16,22.2s-3.89,19.58-9.16,22.19v21.23a3.19,3.19,0,1,1-6.37,0V247.47A3.18,3.18,0,0,1,1600.62,244.28Z" />
                      <path class="cls-24" d="M1540.09,1095s-25.07-29.25-1.54-47.83c0,0,17.18-10.4,31.2-9.56,0,0-19.86,33.3-13.44,36.61s19.45-38.69,19.45-38.69,39.11-3.8,51.69,2.25c0,0-33.43,27.36-26.39,29s43.15-25.23,43.15-25.23,36.4,6.2,34.46,28.4-14.48,29.06-20,28.47-37.76-12.9-38.46-8.38,18.7,19.28,32.3,20c0,0-25.67,28.3-42.72,20.13s-18.51-21.11-29.14-26-19.71-4.84-15.32.11,23.63,9.76,29.71,20.52,11.53,18-6.11,15.35-46.33-15.57-47.59-30.35Z" />
                      <path class="cls-24" d="M1660.79,1070.56s-98.86-3.81-120.7,24.46c0,0-4.82,14.47-19.68,27.63l-2.32,6.31s17.13-18.06,23.26-19.15C1541.35,1109.81,1531.77,1074.78,1660.79,1070.56Z" />
                      <path class="cls-24" d="M1520.41,1122.65s-21.22,15.7-22.7,43.74l3.86.34s6.77-29,19-40.33S1520.41,1122.65,1520.41,1122.65Z" />
                      <path class="cls-25" d="M1452.43,1058.24s39.89-22.34,21.27-52.65c0,0-14.89-18.61-30.84-23.4,0,0,8.51,45.2,0,46.27S1437,977.4,1437,977.4s-42-20.2-58.5-18.61c0,0,26.06,44.14,17.55,43.08s-37.76-45.74-37.76-45.74-43.08-8-50,17.55,4.25,38.29,10.64,39.89,47.33,1.06,46.26,6.38-28.71,13.82-44.14,9c0,0,17,42,39.36,39.88s29.25-15.95,43.07-17,23.93,2.66,17,6.38-30.31,1.22-41.48,10.71-20.21,15.35.53,19.6,58,1.6,65.41-14.36Z" />
                      <path class="cls-24" d="M1328,981.66s111.68,36.16,124.44,76.58c0,0-.53,18.08,10.64,38.82v8s-11.7-27.13-18.09-30.85C1445,1074.19,1470,1039.09,1328,981.66Z" />
                      <path class="cls-24" d="M1463.07,1097.06s17.22,26.15,7.4,58l-4.43-1.19s4.3-35.07-4.71-52.7S1463.07,1097.06,1463.07,1097.06Z" />
                      <path class="cls-25" d="M1515.88,1027.43s-52.57-29.44-28-69.4c0,0,19.63-24.53,40.66-30.84,0,0-11.21,59.59,0,61s7.71-67.3,7.71-67.3,55.38-26.64,77.11-24.54c0,0-34.34,58.19-23.13,56.79S1640,892.84,1640,892.84s56.79-10.52,65.9,23.13-5.61,50.48-14,52.58-62.39,1.4-61,8.41,37.86,18.23,58.18,11.92c0,0-22.43,55.38-51.87,52.57s-38.56-21-56.78-22.43-31.55,3.51-22.44,8.41,40,1.61,54.68,14.13,26.64,20.22-.7,25.83-76.41,2.11-86.22-18.93Z" />
                      <path class="cls-24" d="M1679.92,926.49s-147.21,47.67-164,100.94c0,0,.7,23.84-14,51.18v10.51s15.43-35.75,23.84-40.66C1525.7,1048.46,1492.75,1002.2,1679.92,926.49Z" />
                      <path class="cls-24" d="M1501.86,1078.61s-22.7,34.47-9.76,76.44l5.84-1.57s-5.67-46.22,6.21-69.47S1501.86,1078.61,1501.86,1078.61Z" />
                      <path class="cls-22" d="M1514.11,1246.05a103,103,0,0,0,103-103h-206A103,103,0,0,0,1514.11,1246.05Z" />
                      <path class="cls-26" d="M1539.46,1245.27a103.12,103.12,0,0,1-115.78-102.2h25.58A103,103,0,0,0,1539.46,1245.27Z" />
                      <path class="cls-22" d="M1618.52,1251.29H1409.7a12.71,12.71,0,0,1,12.71-12.7h183.41a12.71,12.71,0,0,1,12.7,12.7Z" />
                      <rect class="cls-27" x="1407.74" y="1143.07" width="211.71" height="3.16" />
                    </g>
                  </g>
                </svg>
              </div>

            </td>
          </tr>

          <tr style="margin: 0px;">
            <td style="padding: 12px 36px; text-align: center; color:#555555">
               ${isSuccess?'<span style="font-size: 24px; line-height: 36px; color:#012729; letter-spacing:1px; font-weight:300"> Thank you! <br /> Estimate is Approved!</span>':
               '<span style="font-size: 24px; line-height: 36px; color:#012729; letter-spacing:1px; font-weight:300"> Error occured! <br /> Please contact Support Team</span>'}
              <br />

              <br />
              <table width="100%" border="0" cellpadding="0">
                <tbody>
                  <tr style="background-color: #e0e0e0;">
                    <td style="font-size: 1px">&nbsp;</td>
                  </tr>
                </tbody>
              </table>

              <!-- SECTION 9 -->

              <table>
                <tr>
                  <td style="text-align: center; font-size: 16px; line-height: 24px; padding: 24px 24px 24px 24px;">
                    If you have any questions, contact us at <a href="tel:${branchData.phone}" style="color:#008489; text-decoration: none">${branchData.phone}</a> or email us at <a style="color:#008489; text-decoration: none" href="mailto:${branchData.email}">${branchData.email}</a></span>.
                  </td>
                </tr>
              </table>
              <!-- SECTION 9 -->
              <table width="100%" border="0" cellpadding="0">
                <tbody>
                  <tr style="background-color: #e0e0e0;">
                    <td style="font-size: 1px">&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              <table width="100%" border="0" cellpadding="0">
                <tr>
                  <td style="font-size: 14px; line-height: 24px; padding: 24px 24px 24px 24px; color:#909090; text-align:center;">
                    Powered by <a href="https://whskr.com" style="color:#008489; text-decoration: none">Whskr™</a>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </tbody>
      </table>

  </td>
</tr>
</tbody>
</table>
&nbsp;
</body>

</html>
`);

}
export default class ApproveEstimateHtml extends React.Component  {
    
   state = {isApproved:null,branchData:{}}

    componentDidMount(){
        let token = this.props.extra.token;
        let status = EstimateServices.approveEstimates(token,data=>this.setState({isApproved:data.resultStatus,branchData:data.responseData}));
    }

   

    
    render(){
        let myhtml = getHtml(this.state.isApproved,this.state.branchData);
        return (
          this.state.isApproved === null ? null :
            <div  dangerouslySetInnerHTML={{ __html: myhtml}}/>);
    }
}