import axios from "axios";
import { trackPromise } from 'react-promise-tracker';
import moment from "moment";
import CommonUtil from "../components/util/CommonUtil";
import { momentLocal } from "../components/util/TimeUtil";
import { message} from 'antd';
import CommonServices from "./CommonServices";

export default class PatientServices {


    static fetchAnimalFamily = (dataUpdaterFunc = () => { }) => {
        //dataUpdaterFunc([]);
        //let resultantData = [{ "speciesId": 1, "speciesName": "Canine" }, { "speciesId": 2, "speciesName": "Equine" }];
        let resultantData = [];
        trackPromise(
            axios.get("/api-route/api/v1/service/patient-families")
                .then(response => resultantData = response.data)
                .catch(err => console.log("Error occurred at fetchAnimalFamily API: " + err))
                .finally(() => dataUpdaterFunc(resultantData))

        );
    }

    static fetchBreedByFamily = (familyId, dataUpdaterFunc = () => { }) => {
        //dataUpdaterFunc([]);
        //let resultantData = [{ "breedId": 567, "speciesId": 1, "breedName": "Flemish Giant" }, { "breedId": 568, "speciesId": 1, "breedName": "Lionhead Rabbit" }];
        let resultantData = [];
        trackPromise(
            axios.get("/api-route/api/v1/service/patient-breeds/" + familyId)
                .then(response => resultantData = response.data)
                .catch(err => console.log("Error occurred at fetchBreedByFamily API: " + err))
                .finally(() => dataUpdaterFunc(resultantData))

        );
    }

    static fetchSpeciesByFamily = (familyId, dataUpdaterFunc = () => { }) => {
        //dataUpdaterFunc([]);
        //let resultantData = [{ "breedId": 567, "speciesId": 1, "breedName": "Flemish Giant" }, { "breedId": 568, "speciesId": 1, "breedName": "Lionhead Rabbit" }];
        let resultantData = [];
        trackPromise(
            axios.get("/api-route/api/v1/service/patient-species/" + familyId)
                .then(response => resultantData = response.data)
                .catch(err => console.log("Error occurred at fetchSpeciesByFamily API: " + err))
                .finally(() => dataUpdaterFunc(resultantData))

        );
    }

    static fetchAllPatients = (dataUpdaterFunc = () => { }) => {
        //dataUpdaterFunc([]);
        /*let resultantData = [
    {
        "id": 8506,
        "name": "Lily Ring",
        "breed": "Chihuahua"
    },
    {
        "id": 8507,
        "name": "Mariposita Ring",
        "breed": "Chihuahua"
    },
    {
        "id": 6324,
        "name": "JACK Kaylor",
        "breed": "Chihuahua Mix"
    },
    {
        "id": 6325,
        "name": "JESSIE Kaylor",
        "breed": "Labrador Retriever Mix"
    }]*/

        let resultantData = [];
        trackPromise(
            axios.get("/api-route/api/v1/service/patient")
                .then(response => resultantData = response.data)
                .catch(err => console.log("Error occurred at fetchAllPatients API: " + err))
                .finally(() => dataUpdaterFunc(resultantData))

        );
    }

    static fetchPrimaryDoctosForPatient = (dataUpdaterFunc = () => { }) => {
        //dataUpdaterFunc([]);
        //let resultantData = [{ "breedId": 567, "speciesId": 1, "breedName": "Flemish Giant" }, { "breedId": 568, "speciesId": 1, "breedName": "Lionhead Rabbit" }];
        let resultantData = [];
        trackPromise(
            axios.get("/api-route/api/v1/service/usr-role/dr/" )
                .then(response => resultantData = response.data)
                .catch(err => console.log("Error occurred at fetchPrimaryDoctosForPatient API: " + err))
                .finally(() => dataUpdaterFunc(resultantData))

        );
    }



    static fetchColorByBreed = (breedId, dataUpdaterFunc = () => { }) => {
        //dataUpdaterFunc([]);
        //let resultantData = [{ "patientColorId": 1, "breedId": 567, "patientColorName": "Tri-Color" }, { "patientColorId": 2, "breedId": 567, "patientColorName": "White" }];
        let resultantData = [];
        trackPromise(
            axios.get("/api-route/api/v1/service/patient-colors/")
                .then(response => resultantData = response.data)
                .catch(err => console.log("Error occurred at fetchColorByBreed API: " + err))
                .finally(() => dataUpdaterFunc(resultantData))

        );
    }

    static fetchPatientStatus = ( dataUpdaterFunc = () => { }) => {
        //dataUpdaterFunc([]);
        //let resultantData = [{ "patientColorId": 1, "breedId": 567, "patientColorName": "Tri-Color" }, { "patientColorId": 2, "breedId": 567, "patientColorName": "White" }];
        let resultantData = [];
        trackPromise(
            axios.get("/api-route/api/v1/service/patient-status")
                .then(response => resultantData = response.data)
                .catch(err => console.log("Error occurred at fetchPatientStatus API: " + err))
                .finally(() => dataUpdaterFunc(resultantData))

        );
    }


    static fetchPatientReminders = ( patientid,dataUpdaterFunc = () => { }) => {
        //dataUpdaterFunc([]);
        //let resultantData = [{ "patientColorId": 1, "breedId": 567, "patientColorName": "Tri-Color" }, { "patientColorId": 2, "breedId": 567, "patientColorName": "White" }];
        let resultantData = [];
        trackPromise(
            axios.get("/api-route/api/v1/service/reminder?patient="+patientid)
                .then(response => resultantData = response.data)
                .catch(err => console.log("Error occurred at fetchPatientReminders API: " + err))
                .finally(() => dataUpdaterFunc(resultantData))

        );
    }

    // static fetchPatientById = (patientId, dataUpdaterFunc = () => { }) => {

    //     let resultantData = { "patientId": 5, "patientName": "Woodhouse", "clientId": 1, "pyDoctorId": 1, "clientLastName": "Sikkayan", "breedId": 567, "speciesId": 1, "colorId": 1, "mixedBreed": false, "dob": "1982-02-11", "sexCd": "M", "weight": 4.5, "weightUnitCd": "LB", "weightEstimated": true, "statusCd": "A", "freeForm": [{ "code": "AG", "value": "This is for Alergies" }, { "code": "BA", "value": "This is for Behavioral Alerts" }, { "code": "MC", "value": "Microchip" }, { "code": "MK", "value": "Markings" }, { "code": "OA", "value": "Other Authorized to Care" }, { "code": "RT", "value": "This is for Rabies Tag" }, { "code": "TT", "value": "This is Tattoo" }] };

    //     trackPromise(
    //         axios.get("/api-route/api/v1/service/patient/" + patientId)
    //             .then(response => resultantData = response.data)
    //             .catch(err => console.log("Error occurred at fetchPatientById API: " + err))
    //             .finally(() => {
    //                 if (resultantData.freeForm) {
    //                     resultantData.dob = moment(resultantData.dob)
    //                     resultantData.freeForm = resultantData.freeForm.reduce((obj, item) => ({ ...obj, [item.code]: item.value }), {});
    //                 }
    //                 dataUpdaterFunc(resultantData);

    //             })

    //     );


    // }

    static fetchPatientById = (patientId, dataUpdaterFunc = () => { }) => {

        //let resultantData = { "patientId": 5, "patientName": "Woodhouse", "clientId": 1, "pyDoctorId": 1, "clientLastName": "Sikkayan", "breedId": 567, "speciesId": 1, "colorId": 1, "mixedBreed": false, "dob": "1982-02-11", "sexCd": "M", "weight": 4.5, "weightUnitCd": "LB", "weightEstimated": true, "statusCd": "A", "freeForm": [{ "code": "AG", "value": "This is for Alergies" }, { "code": "BA", "value": "This is for Behavioral Alerts" }, { "code": "MC", "value": "Microchip" }, { "code": "MK", "value": "Markings" }, { "code": "OA", "value": "Other Authorized to Care" }, { "code": "RT", "value": "This is for Rabies Tag" }, { "code": "TT", "value": "This is Tattoo" }] };
        let resultantData = {};
        trackPromise(
            axios.get("/api-route/api/v1/service/patient/" + patientId)
                .then(response => resultantData = response.data)
                .catch(err => console.log("Error occurred at fetchPatientById API: " + err))
                .finally(() => {
                    resultantData.displayDOB = momentLocal(resultantData.dob,"YYYY-MM-DD").format("MM/DD/YYYY");
                    resultantData.age = CommonUtil.getAgeFromYear(resultantData.dob)
                    if (resultantData.freeForm) {
                        resultantData.dob = momentLocal(resultantData.dob,"YYYY-MM-DD")
                        resultantData.freeForm = resultantData.freeForm.reduce((obj, item) => ({ ...obj, [CommonUtil.FREEFORM_ID_STRING_CONV[item.code].value]: item.value }), {});
                    }
                    
                   // console.log("resuklt ===>",resultantData);
                    dataUpdaterFunc(resultantData);

                })

        );


    }



    static createPatient = (inputData,uploadPhoto,dataUpdaterFunc = () => { },updateStateFields) => {

        let resultantData = 0;
        trackPromise(
            axios.post("/api-route/api/v1/service/patient/", inputData)
                .then(response => resultantData=response.data)
                .catch(err => console.log("Error occurred at createPatient API: " + err))
                .finally( () => {
                    if(uploadPhoto && resultantData){
                        this.uploadPatientPic(resultantData,uploadPhoto,()=>{dataUpdaterFunc(true);});
                    }else{
                        dataUpdaterFunc(!!resultantData);
                    }

                    if(resultantData){
                        message.success("Patient "+CommonUtil.status_messages.create_success)
                    } else {
                        message.error(CommonUtil.status_messages.error)
                    }

                    CommonServices.getAllClientsAndPatients(updateStateFields);
                    
                })

        );


    }

    static updatePatient = (inputData,dataUpdaterFunc = () => { }) => {
        let resultStatus = true;
        let errormessage = ""
        trackPromise(
            axios.put("/api-route/api/v1/service/patient/" , inputData)
                .then(response => response.data)
                .catch(err => {
                    if(err.status === 400)errormessage = err.data;
                    resultStatus=false;console.log("Error occurred at updatePatient API: " + err);})
                .finally( () => {
                    if(resultStatus){
                        message.success("Patient "+ CommonUtil.status_messages.update_success)
                    } else {
                        message.error( errormessage.toString() ?? CommonUtil.status_messages.error)
                    }
                    dataUpdaterFunc(resultStatus);
                })

        );



    }

    static deletePatient = (patientId,dataUpdaterFunc = () => { },updateStateFields) => {
        let resultStatus = true;
        trackPromise(
            axios.delete("/api-route/api/v1/service/patient/" + patientId)
                .then(response => response.data)
                .catch(err => {resultStatus=false;console.log("Error occurred at deletePatient API: " + err)})
                .finally(() => {
                    if(resultStatus){
                        message.success("Patient "+CommonUtil.status_messages.delete_success)
                    } else {
                        message.error(CommonUtil.status_messages.error)
                    }
                    CommonServices.getAllClientsAndPatients(updateStateFields);
                    dataUpdaterFunc();
                })


        );

        



    }


    static updateMicrochipByPatientId = (inputData,dataUpdaterFunc = () => { }) => {
        trackPromise(
            axios.put("/api-route/api/v1/service/patient/chip" ,inputData)
                .then(response => response.data)
                .catch(err => console.log("Error occurred at updateMicrochipByPatientId API: " + err))
                .finally(() => {
                    dataUpdaterFunc(true);
                })


        );

        



    }


    static uploadPatientPic = (id,inputFile, dataUpdaterFunc = () => { }) => {

        const formData = new FormData();
        formData.append('file',inputFile)
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        let resultStatus = true;
        trackPromise(
            axios.put("/api-route/api/v1/service/patient/"+id+"/logo", formData,config)
                .then(response => response.data)
                .catch(err => { resultStatus = false; })
                .finally(() => {
                    dataUpdaterFunc(resultStatus)
                }

                )
        );

    }

   

    



}