import { message } from "antd";
import axios from "axios";
import {trackPromise} from 'react-promise-tracker';
import CommonUtil from "../components/util/CommonUtil";

export default class StaffServices {

    static fetchStaff = (dataUpdaterFunc = () => {
    }) => {
        dataUpdaterFunc([]);
        let resultantData = [
            // {
            //     "userId": "4",
            //     "firstName": "newFirst1",
            //     "lastName": "newLast1",
            //     "role": "Front Desk",
            //     workingDays: ['FRI', 'WED', 'MON']
            // },
            // {
            //     "userId": "5",
            //     "firstName": "newFirst1",
            //     "lastName": "newLast1",
            //     "role": "Vet Technician",
            //     workingDays: ['SAT', 'SUN', 'WED']
            // },
            // {
            //     "userId": "6",
            //     "firstName": "newFirst1",
            //     "lastName": "newLast1",
            //     "role": "Doctor",
            //     workingDays: ['MON', 'SAT', 'SUN']
            // },
            // {
            //     "userId": "7",
            //     "firstName": "newFirst1",
            //     "lastName": "newLast1",
            //     "role": "Doctor",
            //     workingDays: ['FRI', 'WED', 'MON']
            // },
            // {
            //     "userId": "8",
            //     "firstName": "newFirst1",
            //     "lastName": "newLast1",
            //     "role": "Doctor",
            //     workingDays: ['FRI', 'WED', 'MON']
            // },
            // {
            //     "userId": "9",
            //     "firstName": "TestFirstName",
            //     "lastName": "TestLastName",
            //     "role": "Leadership",
            //     workingDays: ['FRI', 'WED', 'MON']
            // }
        ]
        trackPromise(
            axios.get("/api-route/api/v1/service/usr")
                .then(response => resultantData = response.data)
                .catch(err => console.log("Error occurred at staff API: " + err))
                .finally(() => {
                    dataUpdaterFunc(resultantData);
                })
        );
    }
    static fetchWorkingHrsById = (staffId, dataUpdaterFunc = () => {
    }) => {
        // dataUpdaterFunc([]);
        // let resultantData = {
        //     "userId": 9,
        //     "workingHours": [
        //         {
        //             "workignDayCd": "MON",
        //             "stTime": "08:30:00",
        //             "endTime": "04:30:00",
        //             "createdTime": null
        //         },
        //         {
        //             "workignDayCd": "TUE",
        //             "stTime": "08:30:00",
        //             "endTime": "04:30:00",
        //             "createdTime": null
        //         }
        //     ],
        //     "exceptions": [
        //         {
        //             "workignDayCd": "MON",
        //             "stTime": "01:30:00",
        //             "endTime": "02:30:00",
        //             "createdTime": null
        //         },
        //         {
        //             "workignDayCd": "TUE",
        //             "stTime": "02:30:00",
        //             "endTime": "03:30:00",
        //             "createdTime": null
        //         }
        //     ]
        // }
        let resultantData = {};
        trackPromise(
            axios.get("/api-route/api/v1/service/usr-hours/" + staffId)
                .then(response => resultantData = response.data)
                .catch(err => console.log("Error occurred at fetchWorkingHrsById API: " + err))
                .finally(() => {
                    dataUpdaterFunc(resultantData);
                })
        );
    }
    static fetchStaffById = (staffId, dataUpdaterFunc = () => {
    }) => {
        dataUpdaterFunc({});
        let resultantData = {

    "userId": 1,
    "firstName": "Hayley",
    "lastName": "Grove",
    "doctor": true,
    "license": "008098",
    "address1": "2035 LAKE-PARK DR",
    "address2": "APT H",
    "city": "SMYRNA",
    "stateId": 13,
    "zip": "30080",
    "mobilePhone": "1212121212",
    "altPhone": "3434343434",
    "email": "hayleymgrove@gmail.com",
    "permission": "LD"
}
            
        
        trackPromise(
            axios.get("/api-route/api/v1/service/usr/" + staffId)
                .then(response => resultantData = response.data)
                .catch(err => console.log("Error occurred at fetchStaffDataById API: " + err))
                .finally(() => {
                    dataUpdaterFunc(resultantData);
                })
        );
    }

    static createStaff = (inputData,callBack) => {
        let resultStatus = true;
        let errormessage = ""
        trackPromise(
            axios.post("/api-route/api/v1/service/usr", inputData)
                .then(response => console.log("success"))
                .catch(err => {
                    if(err.status === 400)errormessage = err.data;
                    resultStatus=false;console.log("Error occurred at createStaff API: " + err);})
                .finally( () => {
                    if(resultStatus){
                        message.success("Staff "+CommonUtil.status_messages.create_success)
                    } else {

                        message.error ( errormessage ?? CommonUtil.status_messages.error)
                    }
                 
                    callBack(resultStatus);
                }
            
                )
               
        );
    }
    static updateStaff = (inputData,callBack) => {
        let resultStatus = true;
        let errormessage = "";
        trackPromise(
            axios.put("/api-route/api/v1/service/usr", inputData)
                .then(response => console.log("success"))
                .catch(err => {
                    if(err.status === 400)errormessage = err.data;
                    resultStatus=false;console.log("Error occurred at updateStaff API: " + err);})
                .finally( () => {
                    if(resultStatus){
                        message.success("Staff "+CommonUtil.status_messages.update_success)
                    } else {
                        message.error( errormessage ?? CommonUtil.status_messages.error)
                    }
                 
                    callBack(resultStatus);
                }
            
                )
        );
    }

    

    static updateStaffWorkHours = (inputData , responseFunc) => {
        let resultStatus = true;
        trackPromise(
            axios.put("/api-route/api/v1/service/usr-hours/", inputData)
                .then(response => console.log("success"))
                .catch(err => {resultStatus=false;console.log("Error occurred at updateStaffWorkHours API: " + err);})
                .finally( () => {
                    if(resultStatus){
                        message.success("Staff work hours"+CommonUtil.status_messages.update_success)
                    } else {
                        message.error(CommonUtil.status_messages.error)
                    }
                 
                    responseFunc ();
                }
            
                )
               
        );
    }

    static uploadStaffPic = (id,inputFile, dataUpdaterFunc = () => { }) => {

        const formData = new FormData();
        formData.append('file',inputFile)
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        let resultStatus = true;
        trackPromise(
            axios.put("/api-route/api/v1/service/usr/"+id+"/logo", formData,config)
                .then(response => response.data)
                .catch(err => { resultStatus = false; })
                .finally( () => {
                    if(resultStatus){
                        message.success("Staff "+CommonUtil.status_messages.upload_success)
                    } else {
                        message.error(CommonUtil.status_messages.error)
                    }
                    
                    dataUpdaterFunc(resultStatus)
                }
        
                )
        );



    }

    static deleteStaff = (id , dataUpdaterFunc=()=>{}) => {
        let resultStatus = true;
        trackPromise(
         axios.delete("/api-route/api/v1/service/usr/"+id)
        .then(response => response.data)
        .catch(err => {resultStatus=false;console.log("Error occurred at deleteStaff API: " + err);})
        .finally( () => {
                    if(resultStatus){
                        message.success("Staff "+CommonUtil.status_messages.delete_success)
                    } else {
                        message.error(CommonUtil.status_messages.error)
                    }
                 
                    dataUpdaterFunc(resultStatus)
                }
            
                )
      
        );

    }

    static uploadStaffPic = (id,inputFile, dataUpdaterFunc = () => { }) => {

        const formData = new FormData();
        formData.append('file',inputFile)
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        let resultStatus = true;
        trackPromise(
            axios.put("/api-route/api/v1/service/usr/logo", formData,config)
                .then(response => response.data)
                .catch(err => { resultStatus = false; })
                .finally( () => {
                    if(resultStatus){
                        message.success("Staff "+CommonUtil.status_messages.upload_success)
                    } else {
                        message.error(CommonUtil.status_messages.error)
                    }
                    
                    dataUpdaterFunc(resultStatus)
                }
        
                )
        );

    }

    static fetchStaffRemindersById = (staffId, dataUpdaterFunc = () => {
    }) => {
        dataUpdaterFunc({});
        let resultantData = {}
            
        
        trackPromise(
            axios.get("/api-route/api/v1/service/reminder/user/" + staffId)
                .then(response => resultantData = response.data)
                .catch(err => console.log("Error occurred at fetchStaffRemindersById API: " + err))
                .finally(() => {
                    dataUpdaterFunc(resultantData);
                })
        );
    }



}
