import CloverFlex from "./../../images/devices-images/Clover-Flex.png";
import CloverMiniDevice from "./../../images/devices-images/Clover-Mini_Device.png";
import Desk3500iPP315 from "./../../images/devices-images/Desk-3500-iPP315.png";
import Desk5000iPP315 from "./../../images/devices-images/Desk-5000-iPP315.png";
import IDTechVP3000 from "./../../images/devices-images/ID Tech - VP3000.png";
import IDTechaugusta from "./../../images/devices-images/ID Tech-augusta.png";
import IDTechSREDKey2 from "./../../images/devices-images/ID Tech-SREDKey2.png";
import IngenicoDesk3500 from "./../../images/devices-images/Ingenico-Desk3500.png"; 
import IngenicoDesk5000 from "./../../images/devices-images/Ingenico-Desk5000.png"; 
import IngenicoiPP315 from "./../../images/devices-images/Ingenico-iPP315.png"; 
import Ingenicoipp320 from "./../../images/devices-images/Ingenico-ipp320.png"; 
import IngenicoiPP350 from "./../../images/devices-images/Ingenico-iPP350.png"; 
import IngenicoiSC250 from "./../../images/devices-images/Ingenico-iSC250.png"; 
import IngenicoiSMP4 from "./../../images/devices-images/Ingenico-iSMP4.png"; 

const DEVICE_IMAGES = {
     CloverFlex, 
     CloverMiniDevice,
     Desk5000iPP315, 
     IDTechVP3000,
     Desk3500iPP315, 
     Desk3500iPP315, 
     Desk3500iPP315, 
     IDTechaugusta, 
     IDTechSREDKey2, 
     Ingenicoipp320,
     IngenicoDesk3500,
     IngenicoDesk5000,
     IngenicoiPP315,
     IngenicoiPP350,
     IngenicoiSC250,
     IngenicoiSMP4
    }


export {DEVICE_IMAGES};