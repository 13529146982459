import axios from "axios";
import React  from "react";
import { Typography} from 'antd';
import { trackPromise} from 'react-promise-tracker';
import moment from "moment";

const { Text } = Typography;
export default class ClockInOutServices {
    

    static clockIn = (inputData , dataUpdaterFunc=()=>{}) => {
           /*let inputData = {
    "empId" : 10,
    "inOption": 1
}*/
        let resultStatus = true;
        trackPromise(
         axios.post("/api-route/api/v1/service/admin/clock",inputData)
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            dataUpdaterFunc(resultStatus)
        }

        )
        );

    }

    static clockOut = (inputData , dataUpdaterFunc=()=>{}) => {
           /*let inputData = {
"empId" : 10,
"outOption": 1
}*/
        let resultStatus = true;
        trackPromise(
         axios.put("/api-route/api/v1/service/admin/clock",inputData)
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            dataUpdaterFunc(resultStatus)
        }

        )
        );

    }





    static getClockHistoryByEmpId = (id,startdate,enddate,dataUpdaterFunc=()=>{}) => {
           /*let resultantData = [
    {
        "empId": 10,
        "inOption": 1,
        "outOption": 2,
        "inDescr": "Shift Start",
        "outDescr": "Shift End",
        "inTime": "2021-09-16T19:31:09",
        "outTime": "2021-09-16T20:38:08"
    }
]*/
        let resultantData = [];
        let queryparam = (startdate && enddate) ? ("?stDate="+startdate+"&endDate="+enddate) : "";
        trackPromise(
         axios.get("/api-route/api/v1/service/admin/clock/emp/"+id+queryparam)
        .then(response => resultantData=response.data)
        .catch(err => {})
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }

    static deleteItemInClockHistory = (id , dataUpdaterFunc=()=>{}) => {
        /*


        */
        let resultStatus = true;
        trackPromise(
         axios.delete("/api-route/api/v1/service/admin/clock/"+id)
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            dataUpdaterFunc(resultStatus)
        }

        )
        );

    }

    static updateItemInClockHostory = (id,inputData,dataUpdaterFunc=()=>{}) => {
        /*

        {
        "historyId": 3,
        "empId": 9,
        "inOption": 3,
        "outOption": 4,    
        "inTime": "2021-09-24T15:26:50",
        "outTime": "2021-09-24T18:31:27"
    }

        */
        let resultStatus = true;
        trackPromise(
            axios.put("/api-route/api/v1/service/admin/clock/"+id ,inputData)
                .then(response => response.data)
                .catch(err => {resultStatus=false;})
                .finally( () => {
                    dataUpdaterFunc(resultStatus)
                }
                    
                )

        );



    }


    





   


}