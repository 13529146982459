import axios from "axios";
import React  from "react";
import { message, Typography} from 'antd';
import { trackPromise} from 'react-promise-tracker';
import moment from "moment";
import CommonUtil from "../components/util/CommonUtil";

const { Text } = Typography;

export default class NoteServices {

    static createNote = (inputData , dataUpdaterFunc=()=>{}) => {
        /*
    {
    "clientId" : 5,
    "patientId" : 418,
    "title" : "Test Note",
    "description": "Test note description"
}
        */
        let resultStatus = true;
        trackPromise(
         axios.post("/api-route/api/v1/service/note",inputData)
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            if(resultStatus){
                message.success("Notes "+CommonUtil.status_messages.create_success)
            } else {
                message.error(CommonUtil.status_messages.error)
            }
         
            dataUpdaterFunc(resultStatus)
        }
    
        )
        );

    }

    static updateNote = (inputData , dataUpdaterFunc=()=>{}) => {

        /*
       {
    "noteId": 51736,
    "clientId" : 5,
    "patientId" : 418,
    "title" : "Test Note",
    "description": "Test note description Update"
}
        */
        let resultStatus = true;
        trackPromise(
         axios.put("/api-route/api/v1/service/note",inputData)
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            if(resultStatus){
                message.success("Notes "+CommonUtil.status_messages.update_success)
            } else {
                message.error(CommonUtil.status_messages.error)
            }
         
            dataUpdaterFunc(resultStatus)
        }
    
        )
        );

    }



    static getNoteByClientId = (url="",id,dataUpdaterFunc=()=>{}) => {
        /*

    [
    {
        "noteId": 1425,
        "patientId": 416,
        "patientName": "Chloe",
        "title": "Patient Note",
        "description": "SPECIAL INSTRUCTIONS: Methimazole\nDam: A",
        "createdTime": "2018-09-19T06:44:28"
    }
  ]
        */
        let resultantData = [];
        trackPromise(
         axios.get("/api-route/api/v1/service/note/client/"+id+url)
        .then(response => resultantData=response.data)
        .catch(err => console.log("Error occurred at getNoteByClientId API: " + err))
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }


    static getNoteByPatientId = (url="",id,dataUpdaterFunc=()=>{}) => {
        let resultantData = [];
        /*

     [{
        "noteId": 1425,
        "title": "Patient Note",
        "description": "SPECIAL INSTRUCTIONS: Methimazole\nDam: A",
        "createdTime": "2018-09-19T06:44:28"
    }
    ]

        */

        trackPromise(
         axios.get("/api-route/api/v1/service/note/patient/"+id+url)
        .then(response => resultantData=response.data)
        .catch(err => console.log("Error occurred at getNoteByPatientId API: " + err))
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }

    static deleteNotes = (id , dataUpdaterFunc=()=>{}) => {
        let resultStatus = true;
        trackPromise(
         axios.delete("/api-route/api/v1/service/note/"+id)
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            if(resultStatus){
                message.success("Notes "+CommonUtil.status_messages.delete_success)
            } else {
                message.error(CommonUtil.status_messages.error)
            }
         
            dataUpdaterFunc(resultStatus)
        }
    
        )
        );

    }



}