import axios from "axios";
import React from "react";
import { message, Typography } from 'antd';
import { trackPromise } from 'react-promise-tracker';
import moment from "moment";
import CommonUtil from "../components/util/CommonUtil";

const { Text } = Typography;
export default class SettingsServices {


    static updateSettings = (inputData, dataUpdaterFunc = () => { }) => {

        /*
     
     {
         "name": "Trusted Friend Animal Clinic123",
         "email": "trustedfriendvet@gmail.com",
         "phone": "+1 (404) 907-1404",
         "mobile": "",
         "address1": "5975 Roswell Rd., Ste C309123",
         "address2": "123",
         "city": "Sandy Springs123",
         "stateId": 14,
         "zipCode": "30329",
         "logo": "test.png",
         "workingHours": [
             {
                 "workingDayCd": "TUE",
                 "stTime": "10:10:00",
                 "endTime": "16:30:00"
             },
             {
                 "workingDayCd": "WED",
                 "stTime": "10:20:00",
                 "endTime": "16:30:00"
             }
         ]
     }
     
     
        */

        let resultStatus = true;
        trackPromise(
            axios.put("/api-route/api/v1/service/branch-setting", inputData)
                .then(response => response.data)
                .catch(err => { resultStatus = false; })
                .finally( () => {
                    if(resultStatus){
                        message.success("Settings "+CommonUtil.status_messages.update_success)
                    } else {
                        message.error(CommonUtil.status_messages.error)
                    }
                 
                    dataUpdaterFunc(resultStatus)
                }
            
                )
        );

    }


    static getBranchSettings = (dataUpdaterFunc = () => { }) => {

        /*

{
    "name": "Trusted Friend Animal Clinic123",
    "email": "trustedfriendvet@gmail.com",
    "phone": "+1 (404) 907-1404",
    "mobile": "",
    "address1": "5975 Roswell Rd., Ste C309123",
    "address2": "123",
    "city": "Sandy Springs123",
    "stateId": 14,
    "zipCode": "30329",
    "logo": "test.png",
    "workingHours": [
        {
            "workingDayCd": "TUE",
            "stTime": "10:10:00",
            "endTime": "16:30:00"
        },
        {
            "workingDayCd": "WED",
            "stTime": "10:20:00",
            "endTime": "16:30:00"
        }
    ]
}
        */


        let resultantData = {};
        trackPromise(
            axios.get("/api-route/api/v1/service/branch-setting")
                .then(response => resultantData = response.data)
                .catch(err => console.log("Error occurred at getBranchSettings API: " + err))
                .finally(() => {
                    dataUpdaterFunc(resultantData)
                }

                )
        );

    }

    static updateBranchLogo = (inputFile, dataUpdaterFunc = () => { }) => {

        
        const formData = new FormData();
        formData.append('file',inputFile)
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        let resultStatus = true;
        trackPromise(
            axios.put("/api-route/api/v1/service/branch-logo", formData,config)
                .then(response => response.data)
                .catch(err => { resultStatus = false; })
                .finally( () => {
                    if(resultStatus){
                        message.success("Logo "+CommonUtil.status_messages.update_success)
                    } else {
                        message.error(CommonUtil.status_messages.error)
                    }
                 
                    dataUpdaterFunc(resultStatus)
                }
            
                )
        );

    }

    static deleteBranchLogo = (dataUpdaterFunc = () => { }) => {

        let resultStatus = true;
        trackPromise(
            axios.delete("/api-route/api/v1/service/branch-logo")
                .then(response => response.data)
                .catch(err => { resultStatus = false; })
                .finally( () => {
                    if(resultStatus){
                        message.success("Logo Deleted Successfully")
                    } else {
                        message.error(CommonUtil.status_messages.error)
                    }
                 
                    dataUpdaterFunc(resultStatus)
                }
            
                )
        );

    }


    static getBranchIntegration = (dataUpdaterFunc = () => { }) => {

        /*
 
{
"zoetisClientId": "888b38cc-2956-4cf4-b276-24e806e0be5e"
}
        */


        let resultantData = {};
        trackPromise(
            axios.get("/api-route/api/v1/service/branch-integration")
                .then(response => resultantData = response.data)
                .catch(err => console.log("Error occurred at getBranchIntegration API: " + err))
                .finally(() => {
                    dataUpdaterFunc(resultantData)
                }

                )
        );

    }

    static updateBranchIntegration = (inputData,isConnect,dataUpdaterFunc = () => { }) => {

        /*
 
{
"zoetisClientId": "888b38cc-2956-4cf4-b276-24e806e0be5e"
}
        */


        let resultantData = {};
        let url = "/api-route/api/v1/service/branch-integration/"+ (isConnect ? "connect" : "disconnect");
        
        let resultStatus = true;
        trackPromise(
            axios.put(url,inputData)
                .then(response => resultantData = response.data)
                .catch(err =>{resultStatus=false;  console.log("Error occurred at updateBranchIntegration API: " + err)})
                .finally( () => {
                    if(resultStatus){
                        message.success("Lab Integration "+CommonUtil.status_messages.update_success)
                    } else {
                        message.error(CommonUtil.status_messages.error)
                    }
                 
                    dataUpdaterFunc(resultantData)
                }
            
                )
        );

    }

    // static disableBranchIntegration = () => {
    //     trackPromise(
    //         axios.post("/api-route/api/v1/service/lab/disable")
    //             .then(response => console.log("success"))
    //             .catch(err => console.log("Error occurred at disableBranchIntegration API: " + err))
    //             // .finally( () => {
    //             //     callBack(true);
    //             // }
                    
    //             // )
    //     );
    // }


    static getBranchAlerts = (dataUpdaterFunc = () => { }) => {

        /*
 
{
"automatedEmail": true,
"automatedText": true,
"reminderEmailF": 10,
"reminderEmailS": 15,
"reminderTextF": 20,
"reminderTextS": 25
}
        */


        let resultantData = {};
        trackPromise(
            axios.get("/api-route/api/v1/service/branch-alert")
                .then(response => resultantData = response.data)
                .catch(err => console.log("Error occurred at getBranchIntegration API: " + err))
                .finally(() => {
                    dataUpdaterFunc(resultantData)
                }

                )
        );

    }

    static updateBranchAlerts = (inputData,dataUpdaterFunc = () => { }) => {

        /*
 
{
"automatedEmail" : true,
"automatedText" : true,
"reminderEmailF" : 10,
"reminderEmailS" : 15,
"reminderTextF" : 20,
"reminderTextS": 25
}
        */

       let resultStatus = true;
        let resultantData = {};
        trackPromise(
            axios.put("/api-route/api/v1/service/branch-alert",inputData)
                .then(response => resultantData = response.data)
                .catch(err =>{resultStatus=false;console.log("Error occurred at updateBranchAlerts API: " + err)})
                .finally( () => {
                    if(resultStatus){
                        message.success("Branch preferences "+CommonUtil.status_messages.update_success)
                    } else {
                        message.error(CommonUtil.status_messages.error)
                    }
                 
                    dataUpdaterFunc(resultantData)
                }
            
                )
        );

    }

    static getBranchPreferences = (dataUpdaterFunc = () => { }) => {

        /*
 
{
"automatedEmail": true,
"automatedText": true,
"reminderEmailF": 10,
"reminderEmailS": 15,
"reminderTextF": 20,
"reminderTextS": 25
}
        */


        let resultantData = {};
        trackPromise(
            axios.get("/api-route/api/v1/service/branch-pref")
                .then(response => resultantData = response.data)
                .catch(err => console.log("Error occurred at getBranchIntegration API: " + err))
                .finally(() => {
                    dataUpdaterFunc(resultantData)
                }

                )
        );

    }

    static updateBranchPreference = (inputData,dataUpdaterFunc = () => { }) => {

        /*
 
{
"automatedEmail" : true,
"automatedText" : true,
"reminderEmailF" : 10,
"reminderEmailS" : 15,
"reminderTextF" : 20,
"reminderTextS": 25
}
        */

       let resultStatus = true;
        let resultantData = {};
        trackPromise(
            axios.put("/api-route/api/v1/service/branch-pref",inputData)
                .then(response => resultantData = response.data)
                .catch(err =>{resultStatus=false;console.log("Error occurred at updateBranchIntegration API: " + err)})
                .finally( () => {
                    if(resultStatus){
                        message.success("Branch preferences "+CommonUtil.status_messages.update_success)
                    } else {
                        message.error(CommonUtil.status_messages.error)
                    }
                 
                    dataUpdaterFunc(resultantData)
                }
            
                )
        );

    }

    static getBranchPaymentSettings = (dataUpdaterFunc = () => { }) => {

  


        let resultantData = {};
        trackPromise(
            axios.get("/api-route/api/v1/service/branch-payment")
                .then(response => resultantData = response.data)
                .catch(err => console.log("Error occurred at getBranchPaymentSettings API: " + err))
                .finally(() => {
                    dataUpdaterFunc(resultantData)
                }

                )
        );

    }

    static updateBranchPayment= (inputData,isConnect,dataUpdaterFunc = () => { }) => {

        /*
 
{
    "fiservPayment" : {
      "merchantId": "820000000356",
      "userName" : "testing",
      "password" : "testing123"
    }
}
        */

       let resultStatus = true;
        let resultantData = {};
        trackPromise(
            axios.put("/api-route/api/v1/service/branch-payment/"+(isConnect?"connect":"disconnect"),inputData)
                .then(response => resultantData = response.data)
                .catch(err =>{resultStatus=false;console.log("Error occurred at updateBranchIntegration API: " + err)})
                .finally( () => {
                    if(resultStatus){
                        message.success("Branch Payment Settings "+CommonUtil.status_messages.update_success)
                    } else {
                        message.error(CommonUtil.status_messages.error)
                    }
                 
                    dataUpdaterFunc(resultantData)
                }
            
                )
        );

    }

    static updatePaymentDevice= (inputData,isAdd,dataUpdaterFunc = () => { }) => {

  

       let resultStatus = true;
        let resultantData = {};
        trackPromise(
            (isAdd?axios.put("/api-route/api/v1/service/branch-payment/device",inputData)
            :axios.put("/api-route/api/v1/service/branch-payment/device",inputData))
                .then(response => resultantData = response.data)
                .catch(err =>{resultStatus=false;console.log("Error occurred at updatePaymentDevice API: " + err)})
                .finally( () => {
                    if(resultStatus){
                        message.success("Payment Device  "+CommonUtil.status_messages.update_success)
                    } else {
                        message.error(CommonUtil.status_messages.error)
                    }
                 
                    dataUpdaterFunc(resultantData)
                }
            
                )
        );

    }

    static deletePaymentDevices = (inputData,dataUpdaterFunc = () => { }) => {

     

       let resultStatus = true;
        let resultantData = {};
        trackPromise(
            axios.put("/api-route/api/v1/service/branch-payment/device/delete",inputData)
                .then(response => resultantData = response.data)
                .catch(err =>{resultStatus=false;console.log("Error occurred at deletePaymentDevices API: " + err)})
                .finally( () => {
                    if(resultStatus){
                        message.success("Payment Device "+CommonUtil.status_messages.delete_success)
                    } else {
                        message.error(CommonUtil.status_messages.error)
                    }
                 
                    dataUpdaterFunc(resultantData)
                }
            
                )
        );

    }


    static refreshPaymentDevice = (dataUpdaterFunc = () => { }) => {

        let resultStatus = true;
        trackPromise(
            axios.put("/api-route/api/v1/service/branch-payment/device/refresh")
                .then(response => response.data)
                .catch(err => { resultStatus = false; })
                .finally( () => {
                    // if(resultStatus){
                    //     message.success("Logo Deleted Successfully")
                    // } else {
                    //     message.error(CommonUtil.status_messages.error)
                    // }
                 
                    dataUpdaterFunc(resultStatus)
                }
            
                )
        );

    }

    static getConfirmationClinicStaffs = (clinicId,dataUpdaterFunc = () => { }) => {

  


        let resultantData = null;
        let resultantStatus = true;
        trackPromise(
            axios.get("/api-route/api/v1/service/branch-integration/clinic/"+clinicId)
                .then(response => resultantData = response.data)
                .catch(err => {
                    console.log("Error occurred at getConfirmationClinicStaffs API: " + err);
                })
                .finally(() => {
                    if(resultantData){
                        //message.success("Payment Device "+CommonUtil.status_messages.delete_success)
                    } else {
                        message.error("Invalid Clinic Id")
                    }
                    dataUpdaterFunc(resultantData)
                }

                )
        );

    }


}

