import React, {useEffect, useContext} from 'react';
import {Button, Drawer , Form} from 'antd';
import ClientProfileForm from "./client-create-edit-form/ClientCreateEditForm";
import ClientServices from "../../services/ClientServices";
import { CommonContext } from '../../context/CommonContext';

const CreatEditClientDrawer = (props) => {

    
    const [form] = Form.useForm();
    const commonContext = useContext(CommonContext);

    useEffect(()=>{
        form.setFieldsValue({allowEmail:true,clientStatus:"A",allowText: true,optInReminder:true,taxExempt:false,discount:{type:"$"}})
        if(props.clientData){
           
        form.setFieldsValue({...props.clientData});
        }
        

    },[props.clientData]);

    const onClose = () => props.onClose();
    const handleSubmit = (submitData) => {


        if(submitData.phones){
            submitData.phones = Object.keys(submitData.phones).filter(k => submitData.phones[k]).map(k=>({type:k,nbr:submitData.phones[k],preferred:submitData.prefferedPhone===k}))
        }
        if ( props?.clientData?.clientId){
            
            ClientServices.updateClient({...submitData , clientId : props.clientData.clientId} , (isSuccess)=> isSuccess===true ?  props.onSuccessReturn(isSuccess,false) : ()=>{});
        } else {
            ClientServices.createClient(submitData,(isSuccess)=> isSuccess===true ? props.onSuccessReturn(isSuccess,false) : ()=>{},commonContext.updateStateFields);
        }
        //alert(JSON.stringify(submitData))
       
    }


    return (
        <>
            <Drawer className='client-edit-profile'
                title={props.isEdit ? "Edit Client" : "Add Client"}
                width={473}
                onClose={onClose}
                visible={true}
                bodyStyle={{paddingBottom: 50}}
                footer={
                    <div className='footer-button'>
                        {/* <Button
                        type="link"
                        size="large"
                        className="drawerAltButton"
                                style={{marginRight: 8}}>
                            Merge Profile
                        </Button> */}
                        <Button
                            shape="round"
                            size="large" 
                            onClick={onClose}
                            style={{marginRight: 16}}>
                            Cancel
                        </Button>
                        <Button
                            htmlType="submit"
                            form="client-form-id"
                            type="primary"
                            size="large"
                            shape="round">
                            {props.isEdit ? "Update" : "Save"}
                        </Button>
                    </div>
                }>
                    {/* <ProfileImageUpload/> */}
                <ClientProfileForm  clientData= {props.clientData} clientform ={form} handleSubmit={handleSubmit}  isEdit={props.isEdit} allStates={props.allStates} 
                 clientDelete={  () => { ClientServices.deleteClient(props.clientData.clientId, (isSuccess) => props.onSuccessReturn(isSuccess,true),commonContext.updateStateFields);}}
                ></ClientProfileForm>
            </Drawer>
        </>
    );
}

export default CreatEditClientDrawer;
