import axios from "axios";
import React  from "react";
import { message, Typography} from 'antd';
import { trackPromise} from 'react-promise-tracker';
import moment from "moment";
import CommonUtil from "../components/util/CommonUtil";

const { Text } = Typography;
export default class AnesthesiaGeneralServices {


    static createAnesthesiaGeneral = (inputData , dataUpdaterFunc=()=>{}, showError) => {
    /*

        {
    "apptId" : 10,
    "patientId" : 12,
    "surgeon" : 1,
    "diagTest" : "Y",
    "procVerified" : "N",
    "catheter" : "Y",
    "catheterSize" : "20G",
    "location" : 2,
    "ivfluid": "Y",
    "ivfluidType": 2,
    "rate" : 4,
    "breathCircuit" : "R",
    "o2Flow" : 10,
    "sterilePack" : "Y",
    "anesStTime" : "10:30",
    "anesEndTime" : "11:00",
    "surgeryStTime" : "12:30",
    "surgeryEndTime" : "13:00",
    "gas" : 1,
    "asa" : 2,
    "endotrachealTubeSize" : 3.5
}




    */

        let resultStatus = true;
        trackPromise(
         axios.post("/api-route/api/v1/service/exam/anes/gen",inputData)
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {

        if(showError){
            if(resultStatus){
                message.success("Anesthesia General Details "+CommonUtil.status_messages.create_success)
            } else {
                message.error(CommonUtil.status_messages.error)
            }
        }
            dataUpdaterFunc(resultStatus)
        }
    
        )
        );

    }

    static updateAnesthesiaGeneral = (inputData , dataUpdaterFunc=()=>{},showError) => {

        /*

            {
    "id" : 1,
    "apptId" : 10,
    "patientId" : 12,
    "surgeon" : 1,
    "diagTest" : "Y",
    "procVerified" : "N",
    "catheter" : "Y",
    "catheterSize" : "10G",
    "location" : 3,
    "ivfluid": "Y",
    "ivfluidType": 2,
    "rate" : 4,
    "breathCircuit" : "R",
    "o2Flow" : 10,
    "sterilePack" : "Y",
    "anesStTime" : "10:30",
    "anesEndTime" : "11:00",
    "surgeryStTime" : "12:30",
    "surgeryEndTime" : "13:00",
    "gas" : 1,
    "asa" : 2,
    "endotrachealTubeSize" : 3.5
}


        */



        let resultStatus = true;
        trackPromise(
         axios.put("/api-route/api/v1/service/exam/anes/gen",inputData)
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            if(showError){
                if(resultStatus){
                    message.success("Anesthesia General Details "+CommonUtil.status_messages.update_success)
                } else {
                    message.error(CommonUtil.status_messages.error)
                }


            }
           
         
            dataUpdaterFunc(resultStatus)
        }
    
        )
        );

    }


    static getAnesthesiaGeneral = (id,dataUpdaterFunc=()=>{}) => {

        /*

        {
    "id": 1,
    "apptId": 10,
    "patientId": 12,
    "surgeon": 1,
    "diagTest": "Y",
    "procVerified": "N",
    "catheter": "Y",
    "catheterSize": "10G",
    "location": 3,
    "ivfluid": "Y",
    "ivfluidType": 2,
    "rate": 4,
    "breathCircuit": "R",
    "o2Flow": 10,
    "sterilePack": "Y",
    "anesStTime": "10:30:00",
    "anesEndTime": "11:00:00",
    "surgeryStTime": "12:30:00",
    "surgeryEndTime": "13:00:00",
    "gas": 1,
    "asa": 2,
    "endotrachealTubeSize": 3.5
}


        */


        let resultantData = [];
        trackPromise(
         axios.get("/api-route/api/v1/service/exam/anes/gen/"+id)
        .then(response => resultantData=response.data)
        .catch(err => {})
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }

    static getAnesthesiaGeneralByApptId = (id,dataUpdaterFunc=()=>{}) => {


        /*

        {
    "id": 1,
    "apptId": 10,
    "patientId": 12,
    "surgeon": 1,
    "diagTest": "Y",
    "procVerified": "N",
    "catheter": "Y",
    "catheterSize": "10G",
    "location": 3,
    "ivfluid": "Y",
    "ivfluidType": 2,
    "rate": 4,
    "breathCircuit": "R",
    "o2Flow": 10,
    "sterilePack": "Y",
    "anesStTime": "10:30:00",
    "anesEndTime": "11:00:00",
    "surgeryStTime": "12:30:00",
    "surgeryEndTime": "13:00:00",
    "gas": 1,
    "asa": 2,
    "endotrachealTubeSize": 3.5
}


        */

        let resultantData = [];
        trackPromise(
         axios.get("/api-route/api/v1/service/exam/anes/gen/appt/"+id)
        .then(response => resultantData=response.data)
        .catch(err => {})
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }







}